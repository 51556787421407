import React, { useState, useEffect } from "react";
import axios from "axios";

const FormUserEdit = ({
  setNum,
  setEmail,
  forceUpdate,
  setImgCacheBypass,
  confPortfolio,
  setConfPortfolio,
}) => {
  const [selectedImgUserFileName, setSelectedImgUserFileName] =
    useState("None");
  const [userInfoEdit, setUserInfoEdit] = useState({});
  const [msgErrResUserEdit, setMsgErrResUserEdit] = useState("");
  const [isMsgErrUserEdit, setIsMsgErrUserEdit] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "getInfoUtilEditContact", {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setUserInfoEdit(res.data.data);
      });
  }, []);

  const handleInputImgUserChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgUserFileName(file.name);
    }
  };

  const handleContactEditUserSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();
    let configPortfolio = confPortfolio;

    formData.append(
      "nom",
      form.nomEditUser.value !== "" ? form.nomEditUser.value : null
    );
    formData.append(
      "prenom",
      form.prenomEditUser.value !== "" ? form.prenomEditUser.value : null
    );
    formData.append(
      "mail",
      form.emailEditUser.value !== "" ? form.emailEditUser.value : null
    );
    formData.append(
      "num",
      form.numEditUser.value !== "" ? form.numEditUser.value : null
    );

    if (typeof form.imgEditUser.files[0] !== "undefined") {
      formData.append("fileupload", form.imgEditUser.files[0]);
    }

    axios
      .put(process.env.REACT_APP_API_URL + "modifUtilProprInfo", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setIsMsgErrUserEdit(true);
        } else {
          if (
            form.nomEditUser.value !== "" ||
            form.prenomEditUser.value !== "" ||
            form.imgEditUser.files[0] !== "undefined"
          ) {
            axios
              .get(process.env.REACT_APP_API_URL + "infoProprHeader", {
                headers: {
                  identifiant: process.env.REACT_APP_API_ID,
                  authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                },
              })
              .then((res) => {
                let initial = res.data.prenom.charAt(0);

                if (
                  res.data.prenom.includes(" ") ||
                  res.data.prenom.includes("-")
                ) {
                  for (let i = 1; i < res.data.prenom.length; i++) {
                    if (
                      res.data.prenom.charAt(i) === " " ||
                      res.data.prenom.charAt(i) === "-"
                    ) {
                      initial += res.data.prenom.charAt(i + 1);
                    }
                  }
                }

                initial += res.data.nom.charAt(0);

                if (res.data.nom.includes(" ") || res.data.nom.includes("-")) {
                  for (let i = 1; i < res.data.nom.length; i++) {
                    if (
                      res.data.nom.charAt(i) === " " ||
                      res.data.nom.charAt(i) === "-"
                    ) {
                      initial += res.data.nom.charAt(i + 1);
                    }
                  }
                }

                configPortfolio.initial = initial;

                configPortfolio.img =
                  process.env.REACT_APP_API_URL + "ressource/" + res.data.img;

                setConfPortfolio(configPortfolio);

                forceUpdate();
                form.nomEditUser.placeholder = res.data.nom;
                form.prenomEditUser.placeholder = res.data.prenom;
              });
          }

          if (form.emailEditUser.value !== "") {
            setEmail(form.emailEditUser.value.toLowerCase());
            form.emailEditUser.placeholder =
              form.emailEditUser.value.toLowerCase();
          }

          if (form.numEditUser.value !== "") {
            setNum(form.numEditUser.value);
            form.numEditUser.placeholder = form.numEditUser.value;
          }

          setIsMsgErrUserEdit(false);
          form.nomEditUser.value = "";
          form.prenomEditUser.value = "";
          form.emailEditUser.value = "";
          form.numEditUser.value = "";
          form.imgEditUser.value = null;
          setSelectedImgUserFileName("None");
          setImgCacheBypass(Math.random().toString(36));
          setTimeout(() => {
            setMsgErrResUserEdit("");
          }, 4000);
        }

        setMsgErrResUserEdit(res.data.message);
      });
  };

  return (
    <form onSubmit={handleContactEditUserSubmit}>
      <div className="grid-user-edit">
        <div className="grid-user-edit-element grid-user-edit-element-titre">
          <h2>Modifier les informations utilisateur :</h2>
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-court">
          <p className="user-edit-form-titre user-edit-form-titre-nom">Nom :</p>
          <input id="nomEditUser" placeholder={userInfoEdit.nom} type="text" />
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-court">
          <p className="user-edit-form-titre user-edit-form-titre-prenom">
            Prénom :
          </p>
          <input
            id="prenomEditUser"
            placeholder={userInfoEdit.prenom}
            type="text"
          />
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-left">
          <p className="user-edit-form-titre user-edit-form-titre-left">
            Adresse e-mail :
          </p>
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-right">
          <input
            id="emailEditUser"
            placeholder={userInfoEdit.mail}
            type="text"
          />
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-left">
          <p className="user-edit-form-titre user-edit-form-titre-left">
            Numéro de téléphone :
          </p>
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-right">
          <input id="numEditUser" placeholder={userInfoEdit.num} type="text" />
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-long">
          <p className="user-edit-form-titre user-edit-form-titre-long">
            Image :
          </p>
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-long grid-user-edit-element-imgInput">
          <input
            id="imgEditUser"
            type="file"
            onChange={handleInputImgUserChange}
          />
          <label className="btn-upload-img-user-edit" htmlFor="imgEditUser">
            Veuillez choisir un fichier...
          </label>
          <p>
            <span className="titre-img-deco-user-edit">
              Fichier sélectionné :
            </span>
            {" " + selectedImgUserFileName}
          </p>
        </div>

        <div className="grid-user-edit-element grid-user-edit-element-long">
          <button className="btn-contact-user-edit-form-valider" type="submit">
            Valider
          </button>
        </div>

        {msgErrResUserEdit !== "" ? (
          <div className="grid-user-edit-element grid-user-edit-element-long">
            <p
              className={
                "message-err-res-user-edit-contact " +
                (isMsgErrUserEdit
                  ? "message-err-user-edit-contact"
                  : "message-res-user-edit-contact")
              }
            >
              {msgErrResUserEdit}
            </p>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default FormUserEdit;
