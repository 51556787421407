import React, { useState } from "react";
import axios from "axios";

const FormSeparateur = ({ idSousCat, action, add, update, data }) => {
  const [msgErrAddUpdateSpt, setMsgErrAddUpdateSpt] = useState("");

  const handleSubmitSpt = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    switch (action) {
      case "add":
        let formData = new FormData();

        formData.append("titre", form.titrespt.value);

        if (idSousCat !== null) {
          formData.append("idSousCat", idSousCat);
        } else {
          formData.append("idSousCat", "");
        }

        axios
          .post(process.env.REACT_APP_API_URL + "ajoutSeparateur", formData, {
            headers: {
              identifiant: process.env.REACT_APP_API_ID,
              authorization: "Bearer " + process.env.REACT_APP_API_KEY,
            },
          })
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateSpt(res.data.message);
            } else {
              axios
                .get(process.env.REACT_APP_API_URL + "getDernierCptId", {
                  headers: {
                    identifiant: process.env.REACT_APP_API_ID,
                    authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                  },
                })
                .then((res) => {
                  add({
                    id: res.data.dernierCptId,
                    titre: form.titrespt.value,
                    type: "separateur",
                  });
                });
            }
          });

        break;
      case "update":
        let formDataUpdate = new FormData();

        if (form.titrespt.value !== "") {
          formDataUpdate.append("titre", form.titrespt.value);
        } else {
          formDataUpdate.append("titre", null);
        }

        axios
          .put(
            process.env.REACT_APP_API_URL + "modifSeparateur/" + data.id,
            formDataUpdate,
            {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateSpt(res.data.message);
            } else {
              axios
                .get(
                  process.env.REACT_APP_API_URL +
                    "getInfoUpdateCptId/" +
                    data.type +
                    "/" +
                    data.id,
                  {
                    headers: {
                      identifiant: process.env.REACT_APP_API_ID,
                      authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                    },
                  }
                )
                .then((res) => {
                  update({
                    id: data.id,
                    titre: res.data.data.titre,
                    type: data.type,
                  });
                });
            }
          });
        break;

      default:
    }
  };

  return (
    <form onSubmit={handleSubmitSpt}>
      <div className="form-spt-grid">
        <div className="form-spt-grid-element">
          <p className="form-spt-titre-titre">Titre :</p>
        </div>

        <div className="form-spt-grid-element">
          <input
            id="titrespt"
            placeholder={action === "update" ? data.titre : ""}
            type="text"
          />
        </div>

        <div className="form-spt-grid-element">
          <button className="btn-spt-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="form-prj-grid-element">
          {msgErrAddUpdateSpt !== "" ? (
            <p className="msgErrAddUpdateSpt">{msgErrAddUpdateSpt}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormSeparateur;
