import React, { useEffect } from "react";

const AccueilGrid = ({ qsj, mpf }) => {
  useEffect(() => {
    const qsjParagElement = document.getElementById("qsjParagEl");
    const mpfParagElement = document.getElementById("mpfParagEl");

    qsjParagElement.innerHTML = qsj;
    mpfParagElement.innerHTML = mpf;
  }, [qsj, mpf]);

  return (
    <div className="page-accueil-grid">
      <div className="page-accueil-grid-element-titre">
        <img
          className="qsj-img"
          alt="Logo qui suis-je"
          draggable="false"
          height="35"
          width="35"
          src="../img/point-exclamation.png"
        />
        <h2 className="qsj-titre">Qui suis-je ?</h2>
      </div>

      <div className="page-accueil-grid-element">
        <p id="qsjParagEl" className="qsj-paragraphe"></p>
      </div>

      <div className="page-accueil-grid-element-titre">
        <img
          className="mpf-img"
          alt="Logo mon portfolio"
          src="../img/portfolio-logo.png"
          draggable="false"
          height="35"
          width="35"
        />
        <h2 className="mpf-titre">Mon Portfolio :</h2>
      </div>

      <div className="page-accueil-grid-element">
        <p id="mpfParagEl" className="mpf-paragraphe"></p>
      </div>
    </div>
  );
};

export default AccueilGrid;
