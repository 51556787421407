import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HeaderCpt = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let description = document.getElementById("cptDesc");

    description.innerHTML =
      '<span class="header-cpt-desc-lib">Description :</span> ' +
      props.cptInfo.description;
  }, [props]);

  const handleRetour = (event) => {
    event.preventDefault();

    if (props.cptInfo.cat_precedente === null) {
      navigate("/competences");
    } else {
      navigate("/competences/" + props.cptInfo.cat_precedente);
    }
  };

  return (
    <div className="header-cpt-grid">
      <div className="header-cpt-grid-element-titre">
        <i
          onClick={handleRetour}
          className="fa-solid fa-arrow-left header-cpt-btn-retour"
        ></i>
        <h1 className="header-cpt-titre">
          <span className="header-cpt-titre-lib">Titre :</span>
          {" " + props.cptInfo.titre}
        </h1>
      </div>

      <div className="header-cpt-grid-element">
        <img
          className="header-cpt-img"
          alt="Preview d'une catégorie ou d'un projet"
          src={
            process.env.REACT_APP_API_URL +
            "ressource/" +
            props.cptInfo.image +
            "?" +
            props.imgCacheBypass
          }
          draggable="false"
          width="679"
          height="384"
        />
      </div>

      <div className="header-cpt-grid-element border-bottom-cpt-grid">
        <p id="cptDesc" className="header-cpt-desc"></p>
      </div>
    </div>
  );
};

export default HeaderCpt;
