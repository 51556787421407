import React, { useState, useEffect } from "react";
import axios from "axios";

const FormCertif = ({ action, add, certif, update, setImgCacheBypass }) => {
  const [selectedImgCertifFileName, setSelectedImgCertifFileName] =
    useState("None");
  const [liensCertif, setLiensCertif] = useState([]);
  const [liensCertifDelete, setLiensCertifDelete] = useState([]);
  const [msgErrAddUpdateCertif, setMsgErrAddUpdateCertif] = useState("");

  useEffect(() => {
    switch (action) {
      case "add":
        setLiensCertif([
          {
            id: 1,
            titre: "titreliencertif1",
            lien: "liencertif1",
            delete: "deleteliencertif1",
            type: "nouveau",
          },
        ]);
        break;

      case "update":
        let lesLiens = [];

        for (let unLien of certif.liens) {
          lesLiens.push({
            id: unLien.id,
            titre: "titreliencertif" + unLien.id,
            lien: "liencertif" + unLien.id,
            delete: "deleteliencertif" + unLien.id,
            type: "existant",
            data: {
              titreDb: unLien.titre,
              lienDb: unLien.lien,
            },
          });
        }

        setLiensCertif(lesLiens);

        break;

      default:
    }
  }, [action, certif]);

  const handleInputImgCertifChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgCertifFileName(file.name);
    }
  };

  const handleAddLienCertif = (event) => {
    event.preventDefault();

    if (msgErrAddUpdateCertif !== "") {
      setMsgErrAddUpdateCertif("");
    }

    let lesLiensCopie = [...liensCertif];

    lesLiensCopie.push({
      id: lesLiensCopie[liensCertif.length - 1].id + 1,
      titre: "titrelienprj" + (lesLiensCopie[liensCertif.length - 1].id + 1),
      lien: "lienprj" + (lesLiensCopie[liensCertif.length - 1].id + 1),
      delete: "deletelienprj" + (lesLiensCopie[liensCertif.length - 1].id + 1),
      type: "nouveau",
    });

    setLiensCertif(lesLiensCopie);
  };

  const handleDeleteLienCertif = (event, id, type) => {
    event.preventDefault();

    if (msgErrAddUpdateCertif !== "") {
      setMsgErrAddUpdateCertif("");
    }

    let lesLiensCopie = [...liensCertif];

    if (liensCertif.length >= 2) {
      if (action === "update" && type === "existant") {
        const lesLiensDelete = [...liensCertifDelete];
        lesLiensDelete.push(id);
        setLiensCertifDelete(lesLiensDelete);
      }

      const lesLiensUpdateAdd = lesLiensCopie.filter(
        (unLien) => unLien.id !== id
      );

      setLiensCertif(lesLiensUpdateAdd);
    } else {
      setMsgErrAddUpdateCertif(
        "Vous ne pouvez pas supprimer tous les liens, vous devez au minimum avoir un lien !"
      );
    }
  };

  const handleSubmitCertif = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("fileupload", form.imgcertif.files[0]);

    switch (action) {
      case "add":
        formData.append("titre", form.titrecertif.value);

        for (let unLien of liensCertif) {
          formData.append(
            "liens",
            JSON.stringify({
              titre: form[unLien.titre].value,
              lien: form[unLien.lien].value,
            })
          );
        }

        axios
          .post(process.env.REACT_APP_API_URL + "ajoutCertif", formData, {
            headers: {
              identifiant: process.env.REACT_APP_API_ID,
              authorization: "Bearer " + process.env.REACT_APP_API_KEY,
            },
          })
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateCertif(res.data.message);
            } else {
              let nvlCertif = {};

              axios
                .get(process.env.REACT_APP_API_URL + "getDernierCertifId", {
                  headers: {
                    identifiant: process.env.REACT_APP_API_ID,
                    authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                  },
                })
                .then((res) => {
                  nvlCertif.id = res.data.dernierCertifId;
                  nvlCertif.titre = form.titrecertif.value
                    .trim()
                    .split(/[\s\t\n]+/)
                    .join(" ");

                  axios
                    .get(
                      process.env.REACT_APP_API_URL +
                        "getInfoAddUpdateCertifId/" +
                        nvlCertif.id,
                      {
                        headers: {
                          identifiant: process.env.REACT_APP_API_ID,
                          authorization:
                            "Bearer " + process.env.REACT_APP_API_KEY,
                        },
                      }
                    )
                    .then((res) => {
                      nvlCertif.img = res.data.data.img;
                      nvlCertif.liens = res.data.data.liens;

                      add(nvlCertif);
                    });
                });
            }
          });
        break;

      case "update":
        let liensAdd = [];
        let liensUpdate = [];

        formData.append(
          "titre",
          form.titrecertif.value !== "" ? form.titrecertif.value : null
        );

        formData.append(
          "liensDelete",
          liensCertifDelete.length > 0 ? liensCertifDelete : null
        );

        for (let unLien of liensCertif) {
          if (unLien.type === "nouveau") {
            liensAdd.push(
              JSON.stringify({
                titre: form[unLien.titre].value,
                lien: form[unLien.lien].value,
              })
            );
          } else {
            if (
              form[unLien.titre].value !== "" ||
              form[unLien.lien].value !== ""
            ) {
              liensUpdate.push(
                JSON.stringify({
                  id: unLien.id,
                  titre:
                    form[unLien.titre].value !== ""
                      ? form[unLien.titre].value
                      : null,
                  lien:
                    form[unLien.lien].value !== ""
                      ? form[unLien.lien].value
                      : null,
                })
              );
            }
          }
        }

        formData.append("liensAdd", liensAdd.length > 0 ? liensAdd : null);
        formData.append(
          "liensUpdate",
          liensUpdate.length > 0 ? liensUpdate : null
        );

        axios
          .put(
            process.env.REACT_APP_API_URL + "modifCertif/" + certif.id,
            formData,
            {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateCertif(res.data.message);
            } else {
              let modifCertif = {};

              modifCertif.id = certif.id;

              axios
                .get(
                  process.env.REACT_APP_API_URL +
                    "getInfoAddUpdateCertifId/" +
                    modifCertif.id,
                  {
                    headers: {
                      identifiant: process.env.REACT_APP_API_ID,
                      authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                    },
                  }
                )
                .then((res) => {
                  modifCertif.img = res.data.data.img;
                  modifCertif.liens = res.data.data.liens;
                  modifCertif.titre = res.data.data.titre;

                  setImgCacheBypass(Math.random().toString(36));
                  update(modifCertif);
                });
            }
          });

        break;

      default:
    }
  };

  return (
    <form onSubmit={handleSubmitCertif}>
      <div className="form-certif-grid">
        <div className="form-certif-grid-element">
          <p className="form-certif-titre-titre">Titre :</p>
        </div>

        <div className="form-certif-grid-element">
          <input
            id="titrecertif"
            type="text"
            placeholder={action === "update" ? certif.titre : ""}
          />
        </div>

        <div className="form-certif-grid-element">
          <p className="form-certif-titre-img">Image :</p>
        </div>

        <div className="form-certif-grid-element">
          <input
            id="imgcertif"
            type="file"
            onChange={handleInputImgCertifChange}
          />
          <label className="btn-upload-img-certif" htmlFor="imgcertif">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-img-certif-titre">
            <span className="titre-img-certif-deco">Fichier sélectionné :</span>
            {" " + selectedImgCertifFileName}
          </p>
        </div>

        <div className="form-certif-grid-element">
          <p className="form-certif-titre-titre">Liens :</p>
        </div>

        <div className="form-certif-grid-element">
          <div className="form-certif-liens-grid">
            {liensCertif.map((unLien) => {
              return (
                <div key={unLien.id} className="form-certif-liens-grid-form">
                  <div className="form-certif-liens-grid-form-titre">
                    <p className="certif-lien-titre">Titre :</p>
                    <input
                      className="titre-lien-certif"
                      id={unLien.titre}
                      placeholder={
                        unLien.type === "existant" ? unLien.data.titreDb : ""
                      }
                      type="text"
                    />
                  </div>
                  <div className="form-certif-liens-grid-form-lien">
                    <p className="certif-lien">Lien :</p>
                    <input
                      className="lien-certif"
                      id={unLien.lien}
                      placeholder={
                        unLien.type === "existant" ? unLien.data.lienDb : ""
                      }
                      type="text"
                    />
                  </div>
                  <div className="form-certif-liens-grid-form-delete">
                    <button
                      id={unLien.delete}
                      className="btn-certif-delete-lien"
                      onClick={(event) => {
                        handleDeleteLienCertif(event, unLien.id, unLien.type);
                      }}
                    >
                      <i className="fa-regular fa-trash-can"></i>
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="form-certif-liens-grid-add">
              <button
                className="btn-certif-add-lien"
                onClick={handleAddLienCertif}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="form-certif-grid-element">
          <button className="btn-certif-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="form-prj-grid-element">
          {msgErrAddUpdateCertif !== "" ? (
            <p className="msgErrAddUpdateCertif">{msgErrAddUpdateCertif}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormCertif;
