import React from "react";
import FormUserEdit from "./FormUserEdit";
import FormOTPEdit from "./FormOTPEdit";
import FormMdpEdit from "./FormMdpEdit";

const FormContactEdit = ({
  setModifContact,
  setEmail,
  setNum,
  forceUpdate,
  setImgCacheBypass,
  confPortfolio,
  setConfPortfolio,
}) => {
  const handleRetourContactBtn = (event) => {
    event.preventDefault();

    setModifContact(false);
  };

  return (
    <div className="grid-contact-modif-form">
      <div className="grid-contact-modif-form-element">
        <FormUserEdit
          setEmail={setEmail}
          setNum={setNum}
          forceUpdate={forceUpdate}
          setImgCacheBypass={setImgCacheBypass}
          confPortfolio={confPortfolio}
          setConfPortfolio={setConfPortfolio}
        />
      </div>

      <div className="grid-contact-modif-form-element">
        <FormMdpEdit />
      </div>

      <div className="grid-contact-modif-form-element">
        <FormOTPEdit />
      </div>

      <div className="grid-contact-modif-form-element">
        <button onClick={handleRetourContactBtn} className="btn-contact-retour">
          Retour
        </button>
      </div>
    </div>
  );
};

export default FormContactEdit;
