import React from "react";

const Footer = () => {
  return (
    <div>
      <p className="copyright">2024 © NOLLE Damien</p>
    </div>
  );
};

export default Footer;
