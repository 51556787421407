import React, { useEffect } from "react";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Err404Msg from "../components/Err404Msg";

const Err404 = ({ setTitle, isUserExist }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (Erreur 404)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  return (
    <div className="page-grid">
      <div className="page-body-grid">
        <div className="page-body-grid-header"></div>
        <Err404Msg />
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Err404;
