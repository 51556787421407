import React from "react";
import { NavLink } from "react-router-dom";

const Projet = (props) => {
  return (
    <NavLink
      className="projet-lien"
      to={"/competences/" + props.uneCpt.data.urlId}
      draggable="false"
    >
      <div className="grid-projet" draggable="false">
        <div className="grid-projet-element-img">
          <img
            className="projet-img"
            alt="Icone d'un projet"
            src={
              process.env.REACT_APP_API_URL +
              "ressource/" +
              props.uneCpt.data.icone +
              "?" +
              props.imgCacheBypass
            }
            height="100"
            width="100"
            draggable="false"
          />
        </div>
        <div className="grid-projet-element-nom">
          <p className="projet-titre">{props.uneCpt.titre}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Projet;
