import React from "react";
import { NavLink } from "react-router-dom";

const Categorie = (props) => {
  return (
    <NavLink
      className="categorie-lien"
      to={"/competences/" + props.uneCpt.data.urlId}
      draggable="false"
    >
      <div className="grid-categorie" draggable="false">
        <div className="grid-categorie-element-img">
          <img
            className="categorie-img"
            alt="Icone d'une catégorie"
            src="../img/folder.png"
            draggable="false"
            height="100"
            width="100"
          />
        </div>
        <div className="grid-categorie-element-nom">
          <p className="categorie-titre">{props.uneCpt.titre}</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Categorie;
