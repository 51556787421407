import React, { useState } from "react";
import axios from "axios";

const FormContact = () => {
  const [msgErrContact, setMsgErrContact] = useState("");
  const [msgSuccesContact, setMsgSuccesContact] = useState("");

  const handleSubmitContactEnvoyer = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("nom", form.nomcontact.value);
    formData.append("prenom", form.prenomcontact.value);
    formData.append("num", form.numcontact.value);
    formData.append("email", form.emailcontact.value);
    formData.append("msg", form.msgcontact.value);

    axios
      .post(process.env.REACT_APP_API_URL + "SendMail", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setMsgErrContact(res.data.message);
          setMsgSuccesContact("");
        } else {
          setMsgSuccesContact(res.data.message);
          setMsgErrContact("");

          form.nomcontact.value = "";
          form.prenomcontact.value = "";
          form.numcontact.value = "";
          form.emailcontact.value = "";
          form.msgcontact.value = "";
        }
      });
  };

  return (
    <form onSubmit={handleSubmitContactEnvoyer}>
      <div className="page-contact-form-grid">
        <div className="page-contact-form-grid-element-left">
          <p className="page-contact-form-titre">
            <span className="contact-underline">Nom :</span>{" "}
            <span className="contact-star">*</span>
          </p>
          <input id="nomcontact" type="text" />
        </div>

        <div className="page-contact-form-grid-element-right">
          <p className="page-contact-form-titre">
            <span className="contact-underline">Prénom :</span>{" "}
            <span className="contact-star">*</span>
          </p>
          <input id="prenomcontact" type="text" />
        </div>

        <div className="page-contact-form-grid-element-semi-long-left">
          <p className="page-contact-form-titre email-contact-titre">
            <span className="contact-underline">Adresse e-mail :</span>{" "}
            <span className="contact-star">*</span>
          </p>
        </div>

        <div className="page-contact-form-grid-element-semi-long-right">
          <input id="emailcontact" type="text" />
        </div>

        <div className="page-contact-form-grid-element-semi-long-left">
          <p className="page-contact-form-titre num-contact-titre">
            <span className="contact-underline">Numéro de téléphone :</span>
          </p>
        </div>

        <div className="page-contact-form-grid-element-semi-long-right">
          <input id="numcontact" type="text" />
        </div>

        <div className="page-contact-form-grid-element-long">
          <p className="page-contact-form-titre msg-contact-titre">
            <span className="contact-underline">Message :</span>{" "}
            <span className="contact-star">*</span>
          </p>
        </div>

        <div className="page-contact-form-grid-element-long">
          <textarea id="msgcontact" />
        </div>

        <div className="page-contact-form-grid-element-long">
          <button className="btn-contact-form-envoyer" type="submit">
            Envoyer
          </button>
        </div>

        <div className="page-contact-form-grid-element-long">
          <p className="contact-legende">
            <span className="contact-star contact-legende-star">*</span> :
            Champ(s) obligatoire(s).
          </p>
        </div>

        <div className="page-contact-form-grid-element-long">
          {msgErrContact !== "" ? (
            <p className="msgErrContact">{msgErrContact}</p>
          ) : null}

          {msgSuccesContact !== "" ? (
            <p className="msgSuccesContact">{msgSuccesContact}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormContact;
