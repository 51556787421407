import React from "react";
import FormCategorie from "./FormCategorie.js";
import FormProjet from "./FormProjet.js";
import FormSeparateur from "./FormSeparateur.js";

const FormAddCpt = ({
  isAddMenuActive,
  idSousCat,
  libFormActive,
  setIsAddMenuActive,
  setLibFormActive,
  addCat,
  addPrj,
  addSpt,
  setMsgErrDelete,
}) => {
  const handleAddMenu = (event) => {
    event.preventDefault();

    setIsAddMenuActive(true);
    setMsgErrDelete("");
  };

  const handleFormSelect = (event) => {
    event.preventDefault();

    switch (event.target.innerHTML) {
      case "CATEGORIE":
        setLibFormActive("categorie");
        break;
      case "PROJET":
        setLibFormActive("projet");
        break;
      case "SEPARATEUR":
        setLibFormActive("separateur");
        break;
      default:
        setLibFormActive("");
    }
  };

  const formReturn = (formName) => {
    switch (formName) {
      case "categorie":
        return (
          <FormCategorie idSousCat={idSousCat} action="add" add={addCat} />
        );

      case "projet":
        return <FormProjet idSousCat={idSousCat} action="add" add={addPrj} />;

      case "separateur":
        return (
          <FormSeparateur idSousCat={idSousCat} action="add" add={addSpt} />
        );

      default:
        return null;
    }
  };

  const handleRetourAddCpt = (event) => {
    event.preventDefault();

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
    setMsgErrDelete("");
  };

  return isAddMenuActive ? (
    <div className="add-cpt-grid">
      <div className="add-cpt-grid-element-nav">
        <ul>
          <li
            onClick={handleFormSelect}
            className={
              libFormActive === "categorie"
                ? "add-cpt-grid-element-nav-li-focus"
                : "add-cpt-grid-element-nav-li"
            }
          >
            CATEGORIE
          </li>
          <li
            onClick={handleFormSelect}
            className={
              libFormActive === "projet"
                ? "add-cpt-grid-element-nav-li-focus"
                : "add-cpt-grid-element-nav-li"
            }
          >
            PROJET
          </li>
          <li
            onClick={handleFormSelect}
            className={
              libFormActive === "separateur"
                ? "add-cpt-grid-element-nav-li-focus"
                : "add-cpt-grid-element-nav-li"
            }
          >
            SEPARATEUR
          </li>
        </ul>
      </div>
      <div className="add-cpt-grid-element-form">
        {formReturn(libFormActive)}
      </div>

      <div className="add-cpt-grid-element-retour">
        <button onClick={handleRetourAddCpt} className="add-cpt-btn-retour">
          Retour
        </button>
      </div>
    </div>
  ) : (
    <button className="add-cpt-btn" onClick={handleAddMenu}>
      <i className="fa-regular fa-square-plus"></i>
    </button>
  );
};

export default FormAddCpt;
