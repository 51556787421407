import React from "react";

const Certification = (props) => {
  return (
    <div className="certif-card">
      <h2>{props.certif.titre}</h2>
      <img
        alt={"Logo de la certification " + props.certif.titre}
        src={
          process.env.REACT_APP_API_URL +
          "ressource/" +
          props.certif.img +
          "?" +
          props.imgCacheBypass
        }
        draggable="false"
        height="125"
        width="125"
      />
      <div className="certif-card-liens-grid">
        {props.certif.liens.map((unLien) => {
          return (
            <div
              key={unLien.id}
              className={
                "certif-card-liens-grid-element" +
                (props.certif.liens.length === 1
                  ? " certif-card-liens-grid-element-long"
                  : "")
              }
            >
              <a draggable="false" href={unLien.lien}>
                {unLien.titre}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Certification;
