import React from "react";

const Err404Msg = () => {
  return (
    <div className="page-body-err-404-msg">
      <img
        className="img-err404"
        alt="Erreur 404 ressource non trouvé"
        draggable="false"
        height="200"
        width="200"
        src="/img/404-logo.png"
      />
      <h1 className="titre-err404">Erreur 404</h1>
      <p className="paragraphe-err404">
        La ressource demandée n'existe pas, veuillez vérifier votre demande en
        corrigeant l'URL saisie !
      </p>
    </div>
  );
};

export default Err404Msg;
