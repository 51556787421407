import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import BtnDeconnecte from "../components/BtnDeconnecte.js";
import { useNavigate } from "react-router-dom";
import VerifTokenUser from "../services/VerifTokenUser.js";
import FormAccueil from "../components/FormAccueil.js";
import AccueilGrid from "../components/AccueilGrid.js";

const Accueil = ({
  qsjParag,
  mpfParag,
  setQsjParag,
  setMpfParag,
  imgCacheBypass,
  setTitle,
  confPortfolio,
  isUserExist,
}) => {
  const navigate = useNavigate();
  const [modifAcc, setModifAcc] = useState(false);

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    if (
      sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== ""
    ) {
      VerifTokenUser(navigate, "/");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (Accueil)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  const handleEditAccueil = (event) => {
    event.preventDefault();

    setModifAcc(true);
  };

  const handleUpdateAcc = (qsj, mpf) => {
    if (qsj !== "") {
      setQsjParag(qsj);
    }

    if (mpf !== "") {
      setMpfParag(mpf);
    }

    setModifAcc(false);
  };

  return (
    <div className="page-grid">
      {sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== "" ? (
        <div className="header-btn-deconnecte">
          <BtnDeconnecte page="/" />
        </div>
      ) : null}

      <div className="page-body-grid">
        <div className="page-body-grid-header">
          <Navbar
            page="Accueil"
            imgCacheBypass={imgCacheBypass}
            confPortfolio={confPortfolio}
          />
        </div>

        {sessionStorage.getItem("isCnxValid") === "true" &&
        sessionStorage.getItem("cnxId") !== "" &&
        sessionStorage.getItem("cnToken") !== "" &&
        modifAcc ? (
          <div className="page-body-grid-content">
            <FormAccueil
              qsj={qsjParag}
              mpf={mpfParag}
              setModifAcc={setModifAcc}
              update={handleUpdateAcc}
            />
          </div>
        ) : (
          <div className="page-body-grid-content page-body-accueil">
            {sessionStorage.getItem("isCnxValid") === "true" &&
            sessionStorage.getItem("cnxId") !== "" &&
            sessionStorage.getItem("cnToken") !== "" ? (
              <button onClick={handleEditAccueil} className="btn-edit-accueil">
                <i className="fa-solid fa-pencil"></i>
              </button>
            ) : null}

            <AccueilGrid qsj={qsjParag} mpf={mpfParag} />
          </div>
        )}
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Accueil;
