import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormRegister = ({ setIsUserExist }) => {
  const [errRegister, setErrRegister] = useState("");
  const [selectedImgFileName, setSelectedImgFileName] = useState("None");
  const [isPswVisible, setIsPswVisible] = useState(false);
  const [isConfirmPswVisible, setIsConfirmPswVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    setErrRegister("");

    const form = event.currentTarget;

    let formData = new FormData();

    if (form.mdp.value === form.confirmmdp.value) {
      formData.append("fileupload", form.img.files[0]);
      formData.append("nom", form.nom.value);
      formData.append("prenom", form.prenom.value);
      formData.append("identifiant", form.identifiant.value);
      formData.append("mdp", form.mdp.value);
      formData.append("num", form.num.value);
      formData.append("mail", form.mail.value);

      axios
        .post(process.env.REACT_APP_API_URL + "register", formData)
        .then((res) => {
          if (res.data.titre === "Erreur") {
            setErrRegister(res.data.message);
          } else {
            setErrRegister("");
            setSelectedImgFileName("None");
            setIsUserExist(true);
            navigate("/");
          }
        });
    } else {
      setErrRegister(
        "Les deux mots de passe saisis ne correspondent pas, veuillez les ressaisir !"
      );
    }
  };

  const handleInputImgChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgFileName(file.name);
    }
  };

  const handlePswVisible = (event) => {
    event.preventDefault();

    if (event.currentTarget.id === "mdpPswVisible") {
      !isPswVisible ? setIsPswVisible(true) : setIsPswVisible(false);
    } else {
      !isConfirmPswVisible
        ? setIsConfirmPswVisible(true)
        : setIsConfirmPswVisible(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-grid-register">
        <div className="form-grid-register-element-titre-cat">
          <h2>Utilisateur :</h2>
        </div>

        <div className="form-grid-register-element-long">
          <p>Identifiant :</p>
        </div>

        <div className="form-grid-register-element-long">
          <input
            className="form-grid-register-element-long-input"
            id="identifiant"
            type="text"
          />
        </div>

        <div className="form-grid-register-element-long">
          <p>Mot de passe :</p>
        </div>

        <div className="form-grid-register-element-long form-grid-register-element-input-mdp">
          <input
            className="form-grid-register-element-long-input input-mdp"
            id="mdp"
            type={isPswVisible ? "text" : "password"}
          />
          <i
            onClick={handlePswVisible}
            id="mdpPswVisible"
            className={
              "fa-regular " +
              (!isPswVisible
                ? "fa-eye eye-psw-visible"
                : "fa-eye-slash eye-psw-invisible") +
              " eye-psw"
            }
          ></i>
        </div>

        <div className="form-grid-register-element-long">
          <p>Confirmer le mot de passe :</p>
        </div>

        <div className="form-grid-register-element-long form-grid-register-element-input-mdp">
          <input
            className="form-grid-register-element-long-input input-mdp"
            id="confirmmdp"
            type={isConfirmPswVisible ? "text" : "password"}
          />
          <i
            onClick={handlePswVisible}
            id="mdpConfirmPswVisible"
            className={
              "fa-regular " +
              (!isConfirmPswVisible
                ? "fa-eye eye-psw-visible"
                : "fa-eye-slash eye-psw-invisible") +
              " eye-psw"
            }
          ></i>
        </div>

        <div className="form-grid-register-element-titre-cat titre-cat-espace">
          <h2>Information de l'utilisateur :</h2>
        </div>

        <div className="form-grid-register-element-court form-grid-register-element-court-nom">
          <p>Nom :</p>
          <input id="nom" type="text" />
        </div>

        <div className="form-grid-register-element-court form-grid-register-element-court-prenom">
          <p>Prénom :</p>
          <input id="prenom" type="text" />
        </div>

        <div className="form-grid-register-element-long">
          <p>Image :</p>
        </div>

        <div className="form-grid-register-element-long-imgInput">
          <input id="img" type="file" onChange={handleInputImgChange} />
          <label className="btn-upload-img" htmlFor="img">
            Veuillez choisir un fichier...
          </label>
          <p>
            <span className="titre-img-deco">Fichier sélectionné :</span>
            {" " + selectedImgFileName}
          </p>
        </div>

        <div className="form-grid-register-element-court form-grid-register-element-court-num">
          <p>Numéro :</p>
          <input id="num" type="text" />
        </div>

        <div className="form-grid-register-element-court form-grid-register-element-court-email">
          <p>Adresse e-mail :</p>
          <input id="mail" type="text" />
        </div>

        <div className="form-grid-register-element-long">
          <button type="submit">Valider</button>
        </div>

        <div className="form-grid-register-element-err-long">
          {errRegister !== "" ? (
            <p className="message-err-register">{errRegister}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormRegister;
