import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [menuActive, setMenuActive] = useState(false);

  const clickMenu = () => {
    menuActive ? setMenuActive(false) : setMenuActive(true);
  };

  return (
    <div className="navbar-grid">
      <div className="navbar-grid-img">
        <img
          className="navbar-img"
          alt="Logo du site et de l'utilisateur propriétaire du portfolio"
          src={props.confPortfolio.img + "?" + props.imgCacheBypass}
          draggable="false"
          height="172"
          width="172"
        />
      </div>
      <div className="navbar-grid-titre">
        <h1 className="navbar-titre">
          {props.confPortfolio.initial + " - Portfolio"}
        </h1>
      </div>
      <div className="navbar-grid-nav">
        <button
          className={
            menuActive
              ? "fa-solid fa-xmark btn-menu-active"
              : "fa-solid fa-bars btn-menu"
          }
          onClick={clickMenu}
        ></button>

        <ul className={"nav-liste" + (menuActive ? " nav-liste-active" : "")}>
          <NavLink
            draggable="false"
            className={
              "navbar-lien" +
              (props.page === "Accueil"
                ? " navbar-lien-active"
                : " navbar-lien-inactive")
            }
            to="/"
          >
            <li
              className={
                "nav-liste-element-left" +
                (props.page === "Accueil"
                  ? " menu-deroulant-lien-active"
                  : " menu-deroulant-lien-inactive")
              }
            >
              PAGE D'ACCUEIL
            </li>
          </NavLink>

          <li className="nav-liste-element nav-liste-element-slash">|</li>

          <NavLink
            draggable="false"
            className={
              "navbar-lien" +
              (props.page === "Competences"
                ? " navbar-lien-active"
                : " navbar-lien-inactive")
            }
            to="/competences"
          >
            <li
              className={
                "nav-liste-element" +
                (props.page === "Competences"
                  ? " menu-deroulant-lien-active"
                  : " menu-deroulant-lien-inactive")
              }
            >
              COMPETENCES
            </li>
          </NavLink>

          <li className="nav-liste-element nav-liste-element-slash">|</li>

          <NavLink
            draggable="false"
            className={
              "navbar-lien" +
              (props.page === "Certifications"
                ? " navbar-lien-active"
                : " navbar-lien-inactive")
            }
            to="/certifications"
          >
            <li
              className={
                "nav-liste-element" +
                (props.page === "Certifications"
                  ? " menu-deroulant-lien-active"
                  : " menu-deroulant-lien-inactive")
              }
            >
              CERTIFICATIONS
            </li>
          </NavLink>

          <li className="nav-liste-element nav-liste-element-slash">|</li>

          <NavLink
            draggable="false"
            className={
              "navbar-lien" +
              (props.page === "CV"
                ? " navbar-lien-active"
                : " navbar-lien-inactive")
            }
            to="/cv"
          >
            <li
              className={
                "nav-liste-element" +
                (props.page === "CV"
                  ? " menu-deroulant-lien-active"
                  : " menu-deroulant-lien-inactive")
              }
            >
              CV
            </li>
          </NavLink>

          <li className="nav-liste-element nav-liste-element-slash">|</li>

          <NavLink
            draggable="false"
            className={
              "navbar-lien" +
              (props.page === "Contact"
                ? " navbar-lien-active"
                : " navbar-lien-inactive")
            }
            to="/contact"
          >
            <li
              className={
                "nav-liste-element-right" +
                (props.page === "Contact"
                  ? " menu-deroulant-lien-active"
                  : " menu-deroulant-lien-inactive")
              }
            >
              CONTACT
            </li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
