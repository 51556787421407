import React, { useState } from "react";
import axios from "axios";

const FormAccueil = ({ qsj, mpf, setModifAcc, update }) => {
  const [msgModifAccErr, setMsgModifAccErr] = useState("");

  const handleRetourAccBtn = (event) => {
    event.preventDefault();

    setModifAcc(false);
  };

  const handleSubmitFormAcc = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("qsj", form.qsjTextArea.value);
    formData.append("mpf", form.mpfTextArea.value);

    axios
      .put(process.env.REACT_APP_API_URL + "modifAccueil", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setMsgModifAccErr(res.data.message);
        } else {
          update(
            form.qsjTextArea.value
              .trim()
              .split(/[\s\t\n]+/)
              .join(" "),
            form.mpfTextArea.value
              .trim()
              .split(/[\s\t\n]+/)
              .join(" ")
          );
        }
      });
  };

  return (
    <form onSubmit={handleSubmitFormAcc}>
      <div className="page-accueil-grid-form">
        <div className="page-accueil-grid-form-element-titre">
          <img
            className="qsj-img-form"
            alt="Logo qui suis-je"
            src="../img/point-exclamation.png"
            draggable="false"
          />
          <h2 className="qsj-titre-form">Qui suis-je ?</h2>
        </div>

        <div className="page-accueil-grid-form-element">
          <textarea id="qsjTextArea" placeholder={qsj} />
        </div>

        <div className="page-accueil-grid-form-element-titre">
          <img
            className="mpf-img-form"
            alt="Logo mon portfolio"
            src="../img/portfolio-logo.png"
            draggable="false"
          />
          <h2 className="mpf-titre-form">Mon Portfolio :</h2>
        </div>

        <div className="page-accueil-grid-form-element">
          <textarea id="mpfTextArea" placeholder={mpf} />
        </div>

        <div className="page-accueil-grid-form-element">
          <button className="btn-acc-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="page-accueil-grid-form-element">
          <button onClick={handleRetourAccBtn} className="btn-acc-retour">
            Retour
          </button>
        </div>

        <div className="page-accueil-grid-form-element">
          {msgModifAccErr !== "" ? (
            <p className="msgModifAccErr">{msgModifAccErr}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormAccueil;
