import axios from "axios";

const VerifTokenUser = (nav, page) => {
  const reset = () => {
    return setInterval(() => {});
  };

  let intervalReset = reset();

  for (let i = 0; i < intervalReset + 1; i++) {
    clearInterval(i);
  }

  axios
    .get(process.env.REACT_APP_API_URL + "verifTokenUser", {
      headers: {
        identifiant: process.env.REACT_APP_API_ID,
        authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        usertoken: "Bearer " + sessionStorage.getItem("cnxToken"),
      },
    })
    .then(async (res) => {
      if (res.data.data) {
        setInterval(() => {
          axios
            .get(process.env.REACT_APP_API_URL + "verifTokenUser", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                usertoken: "Bearer " + sessionStorage.getItem("cnxToken"),
              },
            })
            .then(async (res) => {
              if (!res.data.data) {
                intervalReset = reset();
                await sessionStorage.setItem("isCnxValid", false);
                await sessionStorage.setItem("cnxId", "");
                await sessionStorage.setItem("cnxToken", "");

                for (let i = 0; i < intervalReset + 1; i++) {
                  clearInterval(i);
                }

                nav(page);
              }
            });
        }, 300000);
      } else {
        await sessionStorage.setItem("isCnxValid", false);
        await sessionStorage.setItem("cnxId", "");
        await sessionStorage.setItem("cnxToken", "");
        nav(page);
      }
    });
};

export default VerifTokenUser;
