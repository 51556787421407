import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import FormLogin from "../components/FormLogin.js";
import FormOTP from "../components/FormOTP.js";
import axios from "axios";

const Login = ({ imgCacheBypass, setTitle, confPortfolio, isUserExist }) => {
  const [errLogin, setErrLogin] = useState("");
  const [isOTPLoginActive, setIsOTPLoginActive] = useState(false);
  const [confLogin, setConfLogin] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    if (
      sessionStorage.getItem("isCnxValid") !== "false" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== ""
    ) {
      navigate("/");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (Connexion)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setErrLogin("");

    const form = event.currentTarget;

    const bodyReq = {
      identifiant: form.identifiant.value,
      mdp: form.mdp.value,
    };

    let confTmp = {};

    axios
      .post(process.env.REACT_APP_API_URL + "login", bodyReq, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setErrLogin(res.data.message);
        } else {
          setErrLogin("");

          confTmp.user = bodyReq;
          confTmp.isCnxValid = res.data.cnx_valide;

          axios
            .get(process.env.REACT_APP_API_URL + "isOTPActive", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              if (res.data.isOTPActive) {
                setConfLogin(confTmp);
                setIsOTPLoginActive(true);
              } else {
                axios
                  .post(
                    process.env.REACT_APP_API_URL + "genTokenUser",
                    confTmp.user,
                    {
                      headers: {
                        identifiant: process.env.REACT_APP_API_ID,
                        authorization:
                          "Bearer " + process.env.REACT_APP_API_KEY,
                      },
                    }
                  )
                  .then((res) => {
                    sessionStorage.setItem("cnxToken", res.data.cnx_token);
                    sessionStorage.setItem("isCnxValid", confTmp.isCnxValid);
                    sessionStorage.setItem("cnxId", confTmp.user.identifiant);
                    navigate("/");
                  });
              }
            });
        }
      });
  };

  return (
    <div className="page-grid">
      <div className="page-body-grid">
        <div className="page-body-grid-header">
          <Navbar
            page=""
            imgCacheBypass={imgCacheBypass}
            confPortfolio={confPortfolio}
          />
        </div>
        <div className="page-body-grid-content">
          {isOTPLoginActive ? (
            <FormOTP confLogin={confLogin} navigate={navigate} />
          ) : (
            <FormLogin handleSubmit={handleSubmit} errLogin={errLogin} />
          )}
        </div>
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Login;
