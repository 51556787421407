import React, { useState } from "react";
import axios from "axios";

const FormOTP = ({ confLogin, navigate }) => {
  const [errOTPMsg, setErrOTPMsg] = useState("");

  const handleSubmitOTP = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("otp_code", form.OTP.value);

    axios
      .post(process.env.REACT_APP_API_URL + "verifOTP", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Succès") {
          setErrOTPMsg("");

          axios
            .post(
              process.env.REACT_APP_API_URL + "genTokenUser",
              confLogin.user,
              {
                headers: {
                  identifiant: process.env.REACT_APP_API_ID,
                  authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                },
              }
            )
            .then((res) => {
              sessionStorage.setItem("cnxToken", res.data.cnx_token);
              sessionStorage.setItem("isCnxValid", confLogin.isCnxValid);
              sessionStorage.setItem("cnxId", confLogin.user.identifiant);
              navigate("/");
            });
        } else {
          setErrOTPMsg(res.data.message);
        }
      });
  };

  return (
    <form onSubmit={handleSubmitOTP}>
      <div className="grid-otp">
        <div className="grid-otp-element">
          <img
            src="./img/google-authenticator.png"
            height="150"
            width="150"
            draggable="false"
            alt="Logo de Google Authenticator"
          />
        </div>

        <div className="grid-otp-element">
          <h2>Double authentification (OTP) :</h2>
        </div>

        <div className="grid-otp-element">
          <input id="OTP" placeholder="XXXXXX" maxLength="6" type="text" />
        </div>

        <div className="grid-otp-element">
          <button className="btn-otp-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="grid-otp-element">
          {errOTPMsg !== "" ? (
            <p className="message-err-otp">{errOTPMsg}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormOTP;
