import React, { useEffect } from "react";
import HeaderRegister from "../components/HeaderRegister";
import Footer from "../components/Footer";
import FormRegister from "../components/FormRegister";
import { useNavigate } from "react-router-dom";

const Register = ({ setTitle, isUserExist, setIsUserExist }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserExist === 1) {
      navigate("/");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (Inscription)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  return (
    <div className="page-grid">
      <div className="page-body-grid">
        <div className="page-body-grid-header">
          <HeaderRegister />
        </div>
        <div className="page-body-grid-content">
          <FormRegister setIsUserExist={setIsUserExist} />
        </div>
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Register;
