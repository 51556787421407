import React from "react";

const BtnLien = (props) => {
  const handleBtnLien = (event) => {
    event.preventDefault();

    window.open(props.unLien.lien, "_blank");
  };

  return props.unLien.titre !== null && props.unLien.titre !== "" ? (
    <div className="btn-lien-grid">
      <div className="btn-lien-grid-element">
        <p className="btn-lien-titre">{props.unLien.titre + " :"}</p>
      </div>

      <div className="btn-lien-grid-element">
        <button onClick={handleBtnLien} className="btn-lien-btn">
          Voir
        </button>
      </div>
    </div>
  ) : (
    <button onClick={handleBtnLien} className="btn-lien-btn">
      Voir
    </button>
  );
};

export default BtnLien;
