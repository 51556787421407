import React, { useState } from "react";
import axios from "axios";

const FormMdpEdit = () => {
  const [isPswVisibleMdpEdit, setIsPswVisibleMdpEdit] = useState(false);
  const [isConfirmPswVisibleMdpEdit, setIsConfirmPswVisibleMdpEdit] =
    useState(false);
  const [msgErrResMdpEdit, setMsgErrResMdpEdit] = useState("");
  const [isMsgErrMdpEdit, setIsMsgErrMdpEdit] = useState(false);

  const handlePswVisible = (event) => {
    event.preventDefault();

    if (event.currentTarget.id === "mdpPswConstactEditVisible") {
      !isPswVisibleMdpEdit
        ? setIsPswVisibleMdpEdit(true)
        : setIsPswVisibleMdpEdit(false);
    } else {
      !isConfirmPswVisibleMdpEdit
        ? setIsConfirmPswVisibleMdpEdit(true)
        : setIsConfirmPswVisibleMdpEdit(false);
    }
  };

  const handleContactEditMdpSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    if (form.mdpContactEdit.value === form.mdpConfirmContactEdit.value) {
      formData.append("mdpEdit", form.mdpContactEdit.value);

      axios
        .put(process.env.REACT_APP_API_URL + "modifUtilProprMdp", formData, {
          headers: {
            identifiant: process.env.REACT_APP_API_ID,
            authorization: "Bearer " + process.env.REACT_APP_API_KEY,
          },
        })
        .then((res) => {
          if (res.data.titre === "Erreur") {
            setIsMsgErrMdpEdit(true);
          } else {
            setIsMsgErrMdpEdit(false);
            form.mdpContactEdit.value = "";
            form.mdpConfirmContactEdit.value = "";
            setTimeout(() => {
              setMsgErrResMdpEdit("");
            }, 4000);
          }

          setMsgErrResMdpEdit(res.data.message);
        });
    } else {
      setMsgErrResMdpEdit(
        "Les deux mots de passe saisis ne correspondent pas, veuillez les ressaisir !"
      );

      setIsMsgErrMdpEdit(true);
    }
  };

  return (
    <form onSubmit={handleContactEditMdpSubmit}>
      <div className="grid-mdp-edit">
        <div className="grid-mdp-edit-element grid-mdp-edit-element-titre">
          <h2>Modifier le mot de passe :</h2>
        </div>

        <div className="grid-mdp-edit-element grid-mdp-edit-element-left">
          <p className="mdp-edit-form-titre mdp-edit-form-titre-left">
            Mot de passe :
          </p>
        </div>

        <div className="grid-mdp-edit-element grid-mdp-edit-element-right">
          <input
            id="mdpContactEdit"
            placeholder="********"
            type={isPswVisibleMdpEdit ? "text" : "password"}
          />
          <i
            onClick={handlePswVisible}
            id="mdpPswConstactEditVisible"
            className={
              "fa-regular " +
              (!isPswVisibleMdpEdit
                ? "fa-eye eye-psw-contact-edit-visible"
                : "fa-eye-slash eye-psw-contact-edit-invisible") +
              " eye-psw-contact-edit"
            }
          ></i>
        </div>

        <div className="grid-mdp-edit-element grid-mdp-edit-element-left">
          <p className="mdp-edit-form-titre mdp-edit-form-titre-left">
            Confirmer le mot de passe :
          </p>
        </div>

        <div className="grid-mdp-edit-element grid-mdp-edit-element-right">
          <input
            id="mdpConfirmContactEdit"
            placeholder="********"
            type={isConfirmPswVisibleMdpEdit ? "text" : "password"}
          />
          <i
            onClick={handlePswVisible}
            id="mdpPswConfirmConstactEditVisible"
            className={
              "fa-regular " +
              (!isConfirmPswVisibleMdpEdit
                ? "fa-eye eye-psw-contact-edit-visible"
                : "fa-eye-slash eye-psw-contact-edit-invisible") +
              " eye-psw-contact-edit"
            }
          ></i>
        </div>

        <div className="grid-mdp-edit-element grid-mdp-edit-element-long">
          <button className="btn-contact-mdp-edit-form-valider" type="submit">
            Valider
          </button>
        </div>

        {msgErrResMdpEdit !== "" ? (
          <div className="grid-mdp-edit-element grid-mdp-edit-element-long">
            <p
              className={
                "message-err-res-mdp-edit-contact " +
                (isMsgErrMdpEdit
                  ? "message-err-mdp-edit-contact"
                  : "message-res-mdp-edit-contact")
              }
            >
              {msgErrResMdpEdit}
            </p>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default FormMdpEdit;
