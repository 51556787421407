import React from "react";

const CVGrid = ({ imgCv, pdfCv, imgCacheBypass }) => {
  return (
    <div className="page-cv-grid">
      <div className="page-cv-grid-element">
        <img
          className="cv-preview"
          src={
            imgCv !== ""
              ? process.env.REACT_APP_API_URL +
                "ressource/" +
                imgCv +
                "?" +
                imgCacheBypass
              : ""
          }
          alt="Prévisualisation du CV"
          draggable="false"
          height="854"
          width="654"
        />
      </div>
      <div className="page-cv-grid-element">
        <a
          className="cv-download-btn"
          draggable="false"
          href={pdfCv}
          download="CV-NOLLE.pdf"
        >
          Télécharger
        </a>
      </div>
    </div>
  );
};

export default CVGrid;
