import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import BtnDeconnecte from "../components/BtnDeconnecte.js";
import { useNavigate } from "react-router-dom";
import VerifTokenUser from "../services/VerifTokenUser.js";
import axios from "axios";
import Certification from "../components/Certification.js";
import FormAddCertif from "../components/FormAddCertif.js";
import FormCertif from "../components/FormCertif.js";

const Certifications = ({
  lesCertifications,
  setLesCertifications,
  imgCacheBypass,
  setImgCacheBypass,
  setTitle,
  confPortfolio,
  isUserExist,
}) => {
  const navigate = useNavigate();
  const [addCertifMenu, setAddCertifMenu] = useState(false);
  const [certifModif, setCertifModif] = useState(null);

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    if (
      sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== ""
    ) {
      VerifTokenUser(navigate, "/certifications");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (Certifications)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  const addCertif = (nvlCertif) => {
    let lesCertifCopie = [...lesCertifications];
    lesCertifCopie.push(nvlCertif);

    setLesCertifications(lesCertifCopie);
    setAddCertifMenu(false);
  };

  const updateCertif = (modifCertif) => {
    let lesCertifCopie = [...lesCertifications];
    let lesCertifUpdate = [];

    for (let uneCertif of lesCertifCopie) {
      if (uneCertif.id !== modifCertif.id) {
        lesCertifUpdate.push(uneCertif);
      } else {
        lesCertifUpdate.push(modifCertif);
      }
    }

    setLesCertifications(lesCertifUpdate);
    setCertifModif(null);
  };

  const handleDeleteCtf = (event, id) => {
    event.preventDefault();

    let lesCertifCopie = [...lesCertifications];
    let lesCertifUpdate = [];

    axios
      .delete(process.env.REACT_APP_API_URL + "supprCertif/" + id, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre !== "Erreur") {
          lesCertifUpdate = lesCertifCopie.filter(
            (uneCertif) => uneCertif.id !== id
          );
        }

        setLesCertifications(lesCertifUpdate);
      });
  };

  const handleEditCertif = (event, certif) => {
    event.preventDefault();

    setCertifModif(certif);
  };

  const handleEditRetourCertif = (event) => {
    event.preventDefault();

    setCertifModif(null);
  };

  return (
    <div className="page-grid">
      {sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== "" ? (
        <div className="header-btn-deconnecte">
          <BtnDeconnecte page="/certifications" />
        </div>
      ) : null}

      <div className="page-body-grid">
        <div className="page-body-grid-header">
          <Navbar
            page="Certifications"
            imgCacheBypass={imgCacheBypass}
            confPortfolio={confPortfolio}
          />
        </div>
        {sessionStorage.getItem("isCnxValid") === "true" &&
        sessionStorage.getItem("cnxId") !== "" &&
        sessionStorage.getItem("cnToken") !== "" &&
        certifModif !== null ? (
          <div className="page-body-grid-content">
            <FormCertif
              action="update"
              certif={certifModif}
              update={updateCertif}
              setImgCacheBypass={setImgCacheBypass}
            />
            <button
              className="update-certif-btn-retour"
              onClick={handleEditRetourCertif}
            >
              Retour
            </button>
          </div>
        ) : (
          <div className="page-body-grid-content">
            <div className="certif-grid">
              {lesCertifications.map((uneCertif) => {
                return (
                  <div key={uneCertif.id} className="certif-grid-element">
                    {sessionStorage.getItem("isCnxValid") === "true" &&
                    sessionStorage.getItem("cnxId") !== "" &&
                    sessionStorage.getItem("cnToken") !== "" ? (
                      <button
                        className="btn-edit-certif"
                        onClick={(event) => {
                          handleEditCertif(event, uneCertif);
                        }}
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </button>
                    ) : null}

                    {sessionStorage.getItem("isCnxValid") === "true" &&
                    sessionStorage.getItem("cnxId") !== "" &&
                    sessionStorage.getItem("cnToken") !== "" ? (
                      <button
                        className="btn-delete-certif"
                        onClick={(event) => {
                          handleDeleteCtf(event, uneCertif.id);
                        }}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </button>
                    ) : null}

                    <Certification
                      certif={uneCertif}
                      imgCacheBypass={imgCacheBypass}
                    />
                  </div>
                );
              })}
            </div>

            {sessionStorage.getItem("isCnxValid") === "true" &&
            sessionStorage.getItem("cnxId") !== "" &&
            sessionStorage.getItem("cnToken") !== "" ? (
              <FormAddCertif
                isAddCertifMenu={addCertifMenu}
                setIsAddCertifMenu={setAddCertifMenu}
                addCertif={addCertif}
              />
            ) : null}
          </div>
        )}
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Certifications;
