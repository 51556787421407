import React from "react";
import FormCertif from "./FormCertif";

const FormAddCertif = ({ isAddCertifMenu, setIsAddCertifMenu, addCertif }) => {
  const handleAddCertifMenu = (event) => {
    event.preventDefault();
    setIsAddCertifMenu(true);
  };

  const handleRetourAddCertif = (event) => {
    event.preventDefault();
    setIsAddCertifMenu(false);
  };

  return isAddCertifMenu ? (
    <div className="add-certif-grid">
      <div className="add-certif-grid-element-form">
        <FormCertif action="add" add={addCertif} />
      </div>

      <div className="add-certif-grid-element-retour">
        <button
          onClick={handleRetourAddCertif}
          className="add-certif-btn-retour"
        >
          Retour
        </button>
      </div>
    </div>
  ) : (
    <button className="add-certif-btn" onClick={handleAddCertifMenu}>
      <i className="fa-regular fa-square-plus"></i>
    </button>
  );
};

export default FormAddCertif;
