import React from "react";

const HeaderRegister = () => {
  return (
    <div className="register-titre-grid">
      <div className="register-titre-grid-element">
        <img
          className="register-user-icon"
          alt="Icon utilisateurs"
          src="./img/user-icon.png"
          draggable="false"
        />
      </div>

      <div className="register-titre-grid-element">
        <h1 className="register-titre">
          Création du compte administrateur du portfolio
        </h1>
      </div>

      <div className="register-titre-grid-element">
        <p className="register-paragraphe-debut">
          Dans le cadre de la première utilisation, vous devez vous inscrire en
          tant que nouvel utilisateur administrateur de ce portfolio. Ce compte
          vous permettra d'accéder aux différentes fonctionnalités du site
          permettant la modification de ce dernier tels que :
        </p>
        <p className="register-paragraphe-tab">
          - L'ajout, la modification ou la suppression d'une ou de plusieurs
          compétences, séparateurs ou projets.
          <br />
          <br />
          - L'ajout, la modification ou la suppression d'une ou plusieurs
          certifications.
          <br />
          <br />
          - La modification des sections "Qui-suis-je" et "Mon Portfolio" de la
          page d'accueil.
          <br />
          <br />
          - La modification de la page CV.
          <br />
          <br />- La modification des informations du compte.
        </p>

        <p className="register-paragraphe-fin">
          En vous inscrivant, vous autorisez la concervation et l'utilisation de
          vos données personnelles par ce portfolio à des fins de traitements
          prévu par et pour ce dernier tels que :
        </p>
        <p className="register-paragraphe-tab">
          - La dynamisation des informations présentes et affichées sur le site.
          <br />
          <br />- L'utilisation des informations de contacts afin de permettre à
          n'importe qui de vous contacter par voie directe (appel téléphonique
          ou e-mail) ou indirecte (formilaire de contact).
        </p>
      </div>
    </div>
  );
};

export default HeaderRegister;
