import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Accueil from "./pages/Accueil.js";
import Certifications from "./pages/Certifications.js";
import Competences from "./pages/Competences.js";
import Contact from "./pages/Contact.js";
import Cv from "./pages/Cv.js";
import Register from "./pages/Register.js";
import Login from "./pages/Login.js";
import Err404 from "./pages/Err404.js";
import axios from "axios";
import { Helmet } from "react-helmet";

const App = () => {
  const [email, setEmail] = useState("");
  const [num, setNum] = useState("");
  const [qsjParag, setQsjParag] = useState("");
  const [mpfParag, setMpfParag] = useState("");
  const [imgCv, setImgCv] = useState("");
  const [pdfCv, setPdfCv] = useState("");
  const [lesCertifications, setLesCertifications] = useState([]);
  const [lesCompetencesAcc, setLesCompetencesAcc] = useState({});
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [imgCacheBypass, setImgCacheBypass] = useState(
    Math.random().toString(36)
  );
  const [title, setTitle] = useState(document.title);

  const [confPortfolio, setConfPortfolio] = useState({ initial: "", img: "" });
  const [isUserExist, setIsUserExist] = useState(-1);

  useEffect(() => {
    let configPortfolio = { initial: "", img: "" };
    const divLoading = document.querySelector("#divLoading");
    const loadReady = document.createElement("div");

    axios
      .get(process.env.REACT_APP_API_URL + "verifUtilExist")
      .then((res) => {
        if (res.data.isUserExist) {
          setIsUserExist(1);
        } else {
          setIsUserExist(0);
        }

        if (isUserExist === 1) {
          axios
            .get(process.env.REACT_APP_API_URL + "infoProprHeader", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              let initial = res.data.prenom.charAt(0);

              if (
                res.data.prenom.includes(" ") ||
                res.data.prenom.includes("-")
              ) {
                for (let i = 1; i < res.data.prenom.length; i++) {
                  if (
                    res.data.prenom.charAt(i) === " " ||
                    res.data.prenom.charAt(i) === "-"
                  ) {
                    initial += res.data.prenom.charAt(i + 1);
                  }
                }
              }

              initial += res.data.nom.charAt(0);

              if (res.data.nom.includes(" ") || res.data.nom.includes("-")) {
                for (let i = 1; i < res.data.nom.length; i++) {
                  if (
                    res.data.nom.charAt(i) === " " ||
                    res.data.nom.charAt(i) === "-"
                  ) {
                    initial += res.data.nom.charAt(i + 1);
                  }
                }
              }

              configPortfolio.initial = initial;

              configPortfolio.img =
                process.env.REACT_APP_API_URL + "ressource/" + res.data.img;

              setConfPortfolio(configPortfolio);

              if (divLoading !== null) {
                divLoading.appendChild(loadReady);
              }
            });

          axios
            .get(process.env.REACT_APP_API_URL + "getUserContactInfo", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setEmail(res.data.data.email);
              setNum(res.data.data.num);
            });

          axios
            .get(process.env.REACT_APP_API_URL + "getQsjMpfAccueil", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setQsjParag(res.data.data.qsj);
              setMpfParag(res.data.data.mpf);
            });

          axios
            .get(process.env.REACT_APP_API_URL + "getImgPdfCV", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setImgCv(res.data.data.imgPreview);

              if (res.data.data.pdf !== "") {
                axios
                  .get(
                    process.env.REACT_APP_API_URL +
                      "ressource/" +
                      res.data.data.pdf,
                    {
                      headers: {
                        identifiant: process.env.REACT_APP_API_ID,
                        authorization:
                          "Bearer " + process.env.REACT_APP_API_KEY,
                      },
                      responseType: "arraybuffer",
                    }
                  )
                  .then(async (res) => {
                    const streamPdf = res.data;
                    const newResponse = new Response(streamPdf);

                    const blob = await newResponse.blob();
                    const url = URL.createObjectURL(blob);

                    setPdfCv(url);
                  });
              } else {
                setPdfCv("");
              }
            });

          axios
            .get(process.env.REACT_APP_API_URL + "getCertifications", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setLesCertifications(res.data.data);
            });

          axios
            .get(process.env.REACT_APP_API_URL + "getCompetences", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setLesCompetencesAcc(res.data);
            });
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });

    if (
      sessionStorage.getItem("isCnxValid") === null ||
      sessionStorage.getItem("cnxId") === null ||
      sessionStorage.getItem("cnxToken") === null
    ) {
      sessionStorage.setItem("isCnxValid", false);
      sessionStorage.setItem("cnxId", "");
      sessionStorage.setItem("cnxToken", "");
    }

    document.addEventListener("contextmenu", (event) => {
      if (
        event.target.tagName.toLowerCase() !== "input" &&
        event.target.tagName.toLowerCase() !== "textarea"
      ) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        return false;
      }
    });

    window.addEventListener("orientationchange", () => {
      const afterOrientationChange = () => {
        let link = document.querySelector("link[rel=stylesheet]");
        const linkCopie = link.getAttribute("href");
        link.setAttribute("href", "");
        link.setAttribute("href", linkCopie);
        forceUpdate();
        window.removeEventListener("resize", afterOrientationChange);
      };

      window.addEventListener("resize", afterOrientationChange);
    });
  }, [forceUpdate, isUserExist]);

  return (
    <div>
      <Helmet>
        <title>{title ? title : "No title"}</title>
      </Helmet>

      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Accueil
                qsjParag={qsjParag}
                mpfParag={mpfParag}
                setQsjParag={setQsjParag}
                setMpfParag={setMpfParag}
                imgCacheBypass={imgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                isUserExist={isUserExist}
              />
            }
          />

          <Route
            path="/certifications"
            element={
              <Certifications
                lesCertifications={lesCertifications}
                setLesCertifications={setLesCertifications}
                imgCacheBypass={imgCacheBypass}
                setImgCacheBypass={setImgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                isUserExist={isUserExist}
              />
            }
          />

          <Route
            path="/competences/:categorieUrlId?"
            element={
              <Competences
                lesCompetencesAcc={lesCompetencesAcc}
                setLesCompetencesAcc={setLesCompetencesAcc}
                imgCacheBypass={imgCacheBypass}
                setImgCacheBypass={setImgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                isUserExist={isUserExist}
              />
            }
          />

          <Route
            path="/contact"
            element={
              <Contact
                email={email}
                num={num}
                setEmail={setEmail}
                setNum={setNum}
                forceUpdate={forceUpdate}
                imgCacheBypass={imgCacheBypass}
                setImgCacheBypass={setImgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                setConfPortfolio={setConfPortfolio}
                isUserExist={isUserExist}
              />
            }
          />

          <Route
            path="/cv"
            element={
              <Cv
                imgCv={imgCv}
                pdfCv={pdfCv}
                setImgCv={setImgCv}
                setPdfCv={setPdfCv}
                imgCacheBypass={imgCacheBypass}
                setImgCacheBypass={setImgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                isUserExist={isUserExist}
              />
            }
          />

          <Route
            path="/register"
            element={
              <Register
                setTitle={setTitle}
                isUserExist={isUserExist}
                setIsUserExist={setIsUserExist}
              />
            }
          />

          <Route
            path="/portfolio-admin"
            element={
              <Login
                imgCacheBypass={imgCacheBypass}
                setTitle={setTitle}
                confPortfolio={confPortfolio}
                isUserExist={isUserExist}
              />
            }
          />
          <Route
            path="*"
            element={<Err404 setTitle={setTitle} isUserExist={isUserExist} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
