import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import BtnDeconnecte from "../components/BtnDeconnecte.js";
import { useNavigate } from "react-router-dom";
import VerifTokenUser from "../services/VerifTokenUser.js";
import CVGrid from "../components/CVGrid.js";
import FormCV from "../components/FormCV.js";
import axios from "axios";

const Cv = ({
  imgCv,
  pdfCv,
  setImgCv,
  setPdfCv,
  imgCacheBypass,
  setImgCacheBypass,
  setTitle,
  confPortfolio,
  isUserExist,
}) => {
  const navigate = useNavigate();
  const [modifCv, setModifCv] = useState(false);

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    if (
      sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== ""
    ) {
      VerifTokenUser(navigate, "/cv");
    }

    if (isUserExist !== -1) {
      setTitle(
        (document.title.includes("(")
          ? document.title.slice(0, document.title.indexOf("("))
          : document.title) + " (CV)"
      );
    }
  }, [navigate, setTitle, isUserExist]);

  const handleEditCv = (event) => {
    event.preventDefault();

    setModifCv(true);
  };

  const update = (img, pdf) => {
    setImgCv(img);

    axios
      .get(process.env.REACT_APP_API_URL + "ressource/" + pdf, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
        responseType: "arraybuffer",
      })
      .then(async (res) => {
        const streamPdf = res.data;
        const newResponse = new Response(streamPdf);

        const blob = await newResponse.blob();
        const url = URL.createObjectURL(blob);

        setPdfCv(url);
        setModifCv(false);
      });
  };

  return (
    <div className="page-grid">
      {sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== "" ? (
        <div className="header-btn-deconnecte">
          <BtnDeconnecte page="/cv" />
        </div>
      ) : null}

      <div className="page-body-grid">
        <div className="page-body-grid-header">
          <Navbar
            page="CV"
            imgCacheBypass={imgCacheBypass}
            confPortfolio={confPortfolio}
          />
        </div>
        {sessionStorage.getItem("isCnxValid") === "true" &&
        sessionStorage.getItem("cnxId") !== "" &&
        sessionStorage.getItem("cnToken") !== "" &&
        modifCv ? (
          <div className="page-body-grid-content">
            <FormCV
              setModifCv={setModifCv}
              update={update}
              setImgCacheBypass={setImgCacheBypass}
            />
          </div>
        ) : (
          <div className="page-body-grid-content page-body-cv">
            {sessionStorage.getItem("isCnxValid") === "true" &&
            sessionStorage.getItem("cnxId") !== "" &&
            sessionStorage.getItem("cnToken") !== "" ? (
              <button onClick={handleEditCv} className="btn-edit-cv">
                <i className="fa-solid fa-pencil"></i>
              </button>
            ) : null}

            <CVGrid
              imgCv={imgCv}
              pdfCv={pdfCv}
              imgCacheBypass={imgCacheBypass}
            />
          </div>
        )}
        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Cv;
