import React, { useState, useEffect } from "react";
import axios from "axios";

const FormProjet = ({
  idSousCat,
  action,
  add,
  update,
  data,
  setImgCacheBypass,
}) => {
  const [selectedImgPrjFileName, setSelectedImgPrjFileName] = useState("None");
  const [selectedIconPrjFileName, setSelectedIconPrjFileName] =
    useState("None");
  const [lesLiens, setLesLiens] = useState([]);
  const [lesLiensDelete, setLesLiensDelete] = useState([]);
  const [msgErrAddUpdatePrj, setMsgErrAddUpdatePrj] = useState("");

  useEffect(() => {
    switch (action) {
      case "add":
        setLesLiens([
          {
            id: 1,
            titre: "titrelienprj1",
            lien: "lienprj1",
            delete: "deletelienprj1",
            type: "nouveau",
          },
        ]);
        break;

      case "update":
        let lesLiensListe = [];

        for (let i = 0; i < data.liens.length; i++) {
          lesLiensListe.push({
            id: i + 1,
            titre: "titrelienprj" + (i + 1),
            lien: "lienprj" + (i + 1),
            delete: "deletelienprj" + (i + 1),
            type: "existant",
            data: {
              idDb: data.liens[i].id,
              titreDb: data.liens[i].titre,
              lienDb: data.liens[i].lien,
            },
          });
        }

        setLesLiens(lesLiensListe);
        break;

      default:
    }
  }, [action, data]);

  const handleInputImgPrjChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgPrjFileName(file.name);
    }
  };

  const handleInputIconPrjChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedIconPrjFileName(file.name);
    }
  };

  const handleSubmitPrj = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    switch (action) {
      case "add":
        let formData = new FormData();

        formData.append("titre", form.titreprj.value);
        formData.append("fileupload", form.imgprj.files[0]);
        formData.append("fileupload", form.iconprj.files[0]);
        formData.append("description", form.descriptionprj.value);

        for (let unLien of lesLiens) {
          formData.append(
            "liens",
            JSON.stringify({
              titre: form[unLien.titre].value,
              lien: form[unLien.lien].value,
            })
          );
        }

        if (idSousCat !== null) {
          formData.append("idSousCat", idSousCat);
        } else {
          formData.append("idSousCat", "");
        }

        axios
          .post(process.env.REACT_APP_API_URL + "ajoutProjet", formData, {
            headers: {
              identifiant: process.env.REACT_APP_API_ID,
              authorization: "Bearer " + process.env.REACT_APP_API_KEY,
            },
          })
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdatePrj(res.data.message);
            } else {
              axios
                .get(process.env.REACT_APP_API_URL + "getDernierCptId", {
                  headers: {
                    identifiant: process.env.REACT_APP_API_ID,
                    authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                  },
                })
                .then((res) => {
                  const cptIdAdd = res.data.dernierCptId;

                  axios
                    .get(
                      process.env.REACT_APP_API_URL +
                        "getInfoAddCptId/projet/" +
                        cptIdAdd,
                      {
                        headers: {
                          identifiant: process.env.REACT_APP_API_ID,
                          authorization:
                            "Bearer " + process.env.REACT_APP_API_KEY,
                        },
                      }
                    )
                    .then((res) => {
                      add({
                        id: cptIdAdd,
                        titre: form.titreprj.value,
                        type: "projet",
                        data: {
                          icone: res.data.data.icone,
                          urlId: res.data.data.urlId,
                        },
                      });
                    });
                });
            }
          });

        break;

      case "update":
        let formDataUpdate = new FormData();
        let verifLiensAdd = 0;
        let verifLienUpdate = 0;

        if (form.titreprj.value !== "") {
          formDataUpdate.append("titre", form.titreprj.value);
        } else {
          formDataUpdate.append("titre", null);
        }

        if (form.descriptionprj.value !== "") {
          formDataUpdate.append("description", form.descriptionprj.value);
        } else {
          formDataUpdate.append("description", null);
        }

        formDataUpdate.append("fileuploadImg", form.imgprj.files[0]);
        formDataUpdate.append("fileuploadIcn", form.iconprj.files[0]);

        for (let unLien of lesLiens) {
          if (
            unLien.type === "existant" &&
            (form[unLien.titre].value !== "" || form[unLien.lien].value !== "")
          ) {
            formDataUpdate.append(
              "liensUpdate",
              JSON.stringify({
                id: unLien.data.idDb,
                titre:
                  form[unLien.titre].value === ""
                    ? null
                    : form[unLien.titre].value,
                lien:
                  form[unLien.lien].value === ""
                    ? null
                    : form[unLien.lien].value,
              })
            );

            verifLienUpdate++;
          }

          if (unLien.type === "nouveau") {
            formDataUpdate.append(
              "liensAdd",
              JSON.stringify({
                titre: form[unLien.titre].value,
                lien: form[unLien.lien].value,
              })
            );

            verifLiensAdd++;
          }
        }

        if (verifLiensAdd === 0) {
          formDataUpdate.append("liensAdd", null);
        }

        if (verifLienUpdate === 0) {
          formDataUpdate.append("liensUpdate", null);
        }

        if (lesLiensDelete.length !== 0) {
          for (let i = 0; i < lesLiensDelete.length; i++) {
            formDataUpdate.append("liensDelete", lesLiensDelete[i]);
          }
        } else {
          formDataUpdate.append("liensDelete", null);
        }

        axios
          .put(
            process.env.REACT_APP_API_URL + "modifProjet/" + data.id,
            formDataUpdate,
            {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdatePrj(res.data.message);
            } else {
              axios
                .get(
                  process.env.REACT_APP_API_URL +
                    "getInfoUpdateCptId/" +
                    data.type +
                    "/" +
                    data.id,
                  {
                    headers: {
                      identifiant: process.env.REACT_APP_API_ID,
                      authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                    },
                  }
                )
                .then((res) => {
                  setImgCacheBypass(Math.random().toString(36));
                  update({
                    id: data.id,
                    titre: res.data.data.titre,
                    type: data.type,
                    data: {
                      urlId: res.data.data.urlId,
                      icone: res.data.data.icone,
                    },
                  });
                });
            }
          });
        break;

      default:
    }
  };

  const handleAddLien = (event) => {
    event.preventDefault();

    if (msgErrAddUpdatePrj !== "") {
      setMsgErrAddUpdatePrj("");
    }

    let lesLiensCopie = [...lesLiens];

    lesLiensCopie.push({
      id: lesLiensCopie[lesLiens.length - 1].id + 1,
      titre: "titrelienprj" + (lesLiensCopie[lesLiens.length - 1].id + 1),
      lien: "lienprj" + (lesLiensCopie[lesLiens.length - 1].id + 1),
      delete: "deletelienprj" + (lesLiensCopie[lesLiens.length - 1].id + 1),
      type: "nouveau",
    });

    setLesLiens(lesLiensCopie);
  };

  const handleDeleteLien = (event, id) => {
    event.preventDefault();

    if (msgErrAddUpdatePrj !== "") {
      setMsgErrAddUpdatePrj("");
    }

    let lesLiensCopie = [...lesLiens];

    if (lesLiens.length >= 2) {
      switch (action) {
        case "add":
          const lesLiensUpdateAdd = lesLiensCopie.filter(
            (unLien) => unLien.id !== id
          );
          setLesLiens(lesLiensUpdateAdd);
          break;

        case "update":
          let lesLiensDeleteCopie = [...lesLiensDelete];
          const lienDelete = lesLiensCopie.filter(
            (unLien) => unLien.id === id && unLien.type === "existant"
          );

          if (lienDelete.length !== 0) {
            lesLiensDeleteCopie.push(lienDelete[0].data.idDb);
          }

          setLesLiensDelete(lesLiensDeleteCopie);

          const lesLiensUpdateUpdate = lesLiensCopie.filter(
            (unLien) => unLien.id !== id
          );

          setLesLiens(lesLiensUpdateUpdate);
          break;

        default:
      }
    } else {
      setMsgErrAddUpdatePrj(
        "Vous ne pouvez pas supprimer tous les liens, vous devez au minimum avoir un lien !"
      );
    }
  };

  return (
    <form onSubmit={handleSubmitPrj}>
      <div className="form-prj-grid">
        <div className="form-prj-grid-element">
          <p className="form-prj-titre-titre">Titre :</p>
        </div>

        <div className="form-prj-grid-element">
          <input
            id="titreprj"
            placeholder={action === "update" ? data.titre : ""}
            type="text"
          />
        </div>

        <div className="form-prj-grid-element">
          <p className="form-prj-titre-img">Image :</p>
        </div>

        <div className="form-prj-grid-element">
          <input id="imgprj" type="file" onChange={handleInputImgPrjChange} />
          <label className="btn-upload-img-prj" htmlFor="imgprj">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-img-prj-titre">
            <span className="titre-img-prj-deco">Fichier sélectionné :</span>
            {" " + selectedImgPrjFileName}
          </p>
        </div>

        <div className="form-prj-grid-element">
          <p className="form-prj-titre-icon">Icône :</p>
        </div>

        <div className="form-prj-grid-element">
          <input id="iconprj" type="file" onChange={handleInputIconPrjChange} />
          <label className="btn-upload-icon-prj" htmlFor="iconprj">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-icon-prj-titre">
            <span className="titre-icon-prj-deco">Fichier sélectionné :</span>
            {" " + selectedIconPrjFileName}
          </p>
        </div>

        <div className="form-prj-grid-element">
          <p className="form-prj-description-titre">Description :</p>
        </div>

        <div className="form-prj-grid-element">
          <textarea
            placeholder={action === "update" ? data.description : ""}
            id="descriptionprj"
          />
        </div>

        <div className="form-prj-grid-element">
          <p className="form-prj-liens-titre">Liens :</p>
        </div>

        <div className="form-prj-grid-element">
          <div className="form-prj-liens-grid">
            {lesLiens.map((unLien) => {
              return (
                <div key={unLien.id} className="form-prj-liens-grid-form">
                  <div className="form-prj-liens-grid-form-titre">
                    <p className="prj-lien-titre">Titre :</p>
                    <input
                      className="titre-lien-prj"
                      id={unLien.titre}
                      placeholder={
                        unLien.type === "existant" ? unLien.data.titreDb : ""
                      }
                      type="text"
                    />
                  </div>
                  <div className="form-prj-liens-grid-form-lien">
                    <p className="prj-lien">Lien :</p>
                    <input
                      className="lien-prj"
                      id={unLien.lien}
                      placeholder={
                        unLien.type === "existant" ? unLien.data.lienDb : ""
                      }
                      type="text"
                    />
                  </div>
                  <div className="form-prj-liens-grid-form-delete">
                    <button
                      id={unLien.delete}
                      onClick={(event) => {
                        handleDeleteLien(event, unLien.id);
                      }}
                      className="btn-prj-delete-lien"
                    >
                      <i className="fa-regular fa-trash-can"></i>
                    </button>
                  </div>
                </div>
              );
            })}
            <div className="form-prj-liens-grid-add">
              <button className="btn-prj-add-lien" onClick={handleAddLien}>
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="form-prj-grid-element">
          <button className="btn-prj-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="form-prj-grid-element">
          {msgErrAddUpdatePrj !== "" ? (
            <p className="msgErrAddUpdatePrj">{msgErrAddUpdatePrj}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormProjet;
