import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import BtnDeconnecte from "../components/BtnDeconnecte.js";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Projet from "../components/Projet.js";
import Separateur from "../components/Separateur.js";
import Categorie from "../components/Categorie.js";
import VerifTokenUser from "../services/VerifTokenUser.js";
import HeaderCpt from "../components/HeaderCpt.js";
import BtnLien from "../components/BtnLien.js";
import FormAddCpt from "../components/FormAddCpt.js";
import FormCategorie from "../components/FormCategorie.js";
import FormProjet from "../components/FormProjet.js";
import FormSeparateur from "../components/FormSeparateur.js";
import Err404Msg from "../components/Err404Msg.js";

const Competences = ({
  lesCompetencesAcc,
  setLesCompetencesAcc,
  imgCacheBypass,
  setImgCacheBypass,
  setTitle,
  confPortfolio,
  isUserExist,
}) => {
  const { categorieUrlId } = useParams();
  const [lesCompetences, setLesCompetences] = useState(lesCompetencesAcc);
  const [isAddMenuActive, setIsAddMenuActive] = useState(false);
  const [libFormActive, setLibFormActive] = useState("categorie");
  const [msgErrDelete, setMsgErrDelete] = useState("");
  const [cptModif, setCptModif] = useState(null);
  const [idSousCat, setIdSousCat] = useState(null);
  const [err404, setErr404] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserExist === 0) {
      navigate("/register");
    }

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
    setMsgErrDelete("");
    setCptModif(null);

    if (typeof categorieUrlId !== "undefined") {
      axios
        .get(
          process.env.REACT_APP_API_URL + "getIdCptByURL/" + categorieUrlId,
          {
            headers: {
              identifiant: process.env.REACT_APP_API_ID,
              authorization: "Bearer " + process.env.REACT_APP_API_KEY,
            },
          }
        )
        .then((res) => {
          if (res.data.titre === "Succès") {
            setIdSousCat(res.data.data);

            axios
              .get(
                process.env.REACT_APP_API_URL +
                  "getCompetences/" +
                  res.data.data,
                {
                  headers: {
                    identifiant: process.env.REACT_APP_API_ID,
                    authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                  },
                }
              )
              .then((res) => {
                setLesCompetences(res.data);
              });
          } else {
            setErr404(true);
          }
        });
    } else {
      setIdSousCat(null);
    }

    if (
      sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== ""
    ) {
      VerifTokenUser(
        navigate,
        "/competences" +
          (typeof categorieUrlId !== "undefined" ? "/" + categorieUrlId : "")
      );
    }

    if (typeof categorieUrlId === "undefined") {
      setLesCompetences(lesCompetencesAcc);
    }

    if (isUserExist !== -1) {
      if (err404) {
        setTitle(
          (document.title.includes("(")
            ? document.title.slice(0, document.title.indexOf("("))
            : document.title) + " (Erreur 404)"
        );
      } else {
        setTitle(
          (document.title.includes("(")
            ? document.title.slice(0, document.title.indexOf("("))
            : document.title) + " (Compétences)"
        );
      }
    }
  }, [
    categorieUrlId,
    setLesCompetences,
    lesCompetencesAcc,
    navigate,
    setTitle,
    err404,
    isUserExist,
  ]);

  const addCat = (nvlCat) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    lesCompetencesDataCopie.push(nvlCat);

    lesCompetencesUpdate.titre = lesCompetences.titre;
    lesCompetencesUpdate.data = lesCompetencesDataCopie;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
  };

  const addPrj = (nvPrj) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    lesCompetencesDataCopie.push(nvPrj);

    lesCompetencesUpdate.titre = lesCompetences.titre;
    lesCompetencesUpdate.data = lesCompetencesDataCopie;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
  };

  const addSpt = (nvSpt) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    lesCompetencesDataCopie.push(nvSpt);

    lesCompetencesUpdate.titre = lesCompetences.titre;
    lesCompetencesUpdate.data = lesCompetencesDataCopie;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
  };

  const updatePrj = (unPrj) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = { data: [] };

    for (let i = 0; i < lesCompetencesDataCopie.length; i++) {
      if (lesCompetencesDataCopie[i].id !== unPrj.id) {
        lesCompetencesUpdate.data.push(lesCompetencesDataCopie[i]);
      } else {
        lesCompetencesUpdate.data.push(unPrj);
      }
    }

    lesCompetencesUpdate.titre = lesCompetences.titre;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setCptModif(null);
  };

  const updateCat = (uneCat) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = { data: [] };

    for (let i = 0; i < lesCompetencesDataCopie.length; i++) {
      if (lesCompetencesDataCopie[i].id !== uneCat.id) {
        lesCompetencesUpdate.data.push(lesCompetencesDataCopie[i]);
      } else {
        lesCompetencesUpdate.data.push(uneCat);
      }
    }

    lesCompetencesUpdate.titre = lesCompetences.titre;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setCptModif(null);
  };

  const updateSpt = (unSpt) => {
    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = { data: [] };

    for (let i = 0; i < lesCompetencesDataCopie.length; i++) {
      if (lesCompetencesDataCopie[i].id !== unSpt.id) {
        lesCompetencesUpdate.data.push(lesCompetencesDataCopie[i]);
      } else {
        lesCompetencesUpdate.data.push(unSpt);
      }
    }

    lesCompetencesUpdate.titre = lesCompetences.titre;

    if (typeof lesCompetences.catPrjData !== "undefined") {
      lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
    }

    setLesCompetences(lesCompetencesUpdate);

    if (typeof categorieUrlId === "undefined") {
      setLesCompetencesAcc(lesCompetencesUpdate);
    }

    setCptModif(null);
  };

  const handleEdit = (event, cptId, cptType) => {
    event.preventDefault();

    setIsAddMenuActive(false);
    setLibFormActive("categorie");
    setMsgErrDelete("");

    axios
      .get(
        process.env.REACT_APP_API_URL +
          "getInfoUpdateCptId/" +
          cptType +
          "/" +
          cptId,
        {
          headers: {
            identifiant: process.env.REACT_APP_API_ID,
            authorization: "Bearer " + process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then((res) => {
        let resCpt = res.data.data;
        resCpt.id = cptId;
        resCpt.type = cptType;
        setCptModif(resCpt);
      });
  };

  const deleteCat = (event, id) => {
    event.preventDefault();

    setMsgErrDelete("");

    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    axios
      .delete(process.env.REACT_APP_API_URL + "supprCategorie/" + id, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setMsgErrDelete(res.data.message);
        } else {
          lesCompetencesUpdate.data = lesCompetencesDataCopie.filter(
            (uneCompetence) => uneCompetence.id !== id
          );

          lesCompetencesUpdate.titre = lesCompetences.titre;

          if (typeof lesCompetences.catPrjData !== "undefined") {
            lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
          }

          setLesCompetences(lesCompetencesUpdate);

          if (typeof categorieUrlId === "undefined") {
            setLesCompetencesAcc(lesCompetencesUpdate);
          }
        }
      });
  };

  const deletePrj = (event, id) => {
    event.preventDefault();

    setMsgErrDelete("");

    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    axios
      .delete(process.env.REACT_APP_API_URL + "supprProjet/" + id, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre !== "Erreur") {
          lesCompetencesUpdate.data = lesCompetencesDataCopie.filter(
            (uneCompetence) => uneCompetence.id !== id
          );

          lesCompetencesUpdate.titre = lesCompetences.titre;

          if (typeof lesCompetences.catPrjData !== "undefined") {
            lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
          }

          setLesCompetences(lesCompetencesUpdate);

          if (typeof categorieUrlId === "undefined") {
            setLesCompetencesAcc(lesCompetencesUpdate);
          }
        }
      });
  };

  const deleteSpt = (event, id) => {
    event.preventDefault();

    setMsgErrDelete("");

    let lesCompetencesDataCopie = [...lesCompetences.data];
    let lesCompetencesUpdate = {};

    axios
      .delete(process.env.REACT_APP_API_URL + "supprSeparateur/" + id, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre !== "Erreur") {
          lesCompetencesUpdate.data = lesCompetencesDataCopie.filter(
            (uneCompetence) => uneCompetence.id !== id
          );

          lesCompetencesUpdate.titre = lesCompetences.titre;

          if (typeof lesCompetences.catPrjData !== "undefined") {
            lesCompetencesUpdate.catPrjData = lesCompetences.catPrjData;
          }

          setLesCompetences(lesCompetencesUpdate);

          if (typeof categorieUrlId === "undefined") {
            setLesCompetencesAcc(lesCompetencesUpdate);
          }
        }
      });
  };

  const formModifReturn = (setImgCacheBypass) => {
    switch (cptModif.type) {
      case "categorie":
        return (
          <FormCategorie
            action="update"
            update={updateCat}
            data={cptModif}
            setImgCacheBypass={setImgCacheBypass}
          />
        );

      case "projet":
        return (
          <FormProjet
            action="update"
            update={updatePrj}
            data={cptModif}
            setImgCacheBypass={setImgCacheBypass}
          />
        );

      case "separateur":
        return (
          <FormSeparateur action="update" update={updateSpt} data={cptModif} />
        );

      default:
        return null;
    }
  };

  const handleRetourUpdateCpt = (event) => {
    event.preventDefault();

    setCptModif(null);
  };

  return (
    <div className="page-grid">
      {sessionStorage.getItem("isCnxValid") === "true" &&
      sessionStorage.getItem("cnxId") !== "" &&
      sessionStorage.getItem("cnToken") !== "" &&
      !err404 ? (
        <div className="header-btn-deconnecte">
          <BtnDeconnecte
            page={
              "/competences" +
              (typeof categorieUrlId !== "undefined"
                ? "/" + categorieUrlId
                : "")
            }
          />
        </div>
      ) : null}

      <div className="page-body-grid">
        <div className="page-body-grid-header">
          {!err404 ? (
            <Navbar
              page="Competences"
              imgCacheBypass={imgCacheBypass}
              confPortfolio={confPortfolio}
            />
          ) : null}
        </div>
        {sessionStorage.getItem("isCnxValid") === "true" &&
        sessionStorage.getItem("cnxId") !== "" &&
        sessionStorage.getItem("cnToken") !== "" &&
        cptModif !== null ? (
          <div className="page-body-grid-content form-modif-cpt-grid">
            {formModifReturn(setImgCacheBypass)}

            <button
              onClick={handleRetourUpdateCpt}
              className="update-cpt-btn-retour"
            >
              Retour
            </button>
          </div>
        ) : err404 ? (
          <Err404Msg />
        ) : (
          <div className="page-body-grid-content">
            {typeof lesCompetences.catPrjData !== "undefined" ? (
              <HeaderCpt
                cptInfo={lesCompetences.catPrjData}
                imgCacheBypass={imgCacheBypass}
              />
            ) : null}

            {(typeof lesCompetences.catPrjData !== "undefined" &&
              lesCompetences.catPrjData.type === "categorie") ||
            typeof lesCompetences.catPrjData === "undefined" ? (
              <div
                className={
                  "cpt-flex" +
                  (typeof lesCompetences.catPrjData !== "undefined"
                    ? " cpt-flex-header"
                    : "")
                }
              >
                {typeof lesCompetences.data !== "undefined"
                  ? lesCompetences.data.map((uneCompetence, index) => {
                      switch (uneCompetence.type) {
                        case "categorie":
                          return (
                            <div
                              key={uneCompetence.id}
                              className="cpt-flex-element"
                            >
                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  onClick={(event) => {
                                    handleEdit(
                                      event,
                                      uneCompetence.id,
                                      uneCompetence.type
                                    );
                                  }}
                                  className="btn-edit-cpt"
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              ) : null}

                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  className="btn-delete-cpt"
                                  onClick={(event) => {
                                    deleteCat(event, uneCompetence.id);
                                  }}
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              ) : null}

                              <Categorie uneCpt={uneCompetence} />
                            </div>
                          );

                        case "projet":
                          return (
                            <div
                              key={uneCompetence.id}
                              className="cpt-flex-element"
                            >
                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  onClick={(event) => {
                                    handleEdit(
                                      event,
                                      uneCompetence.id,
                                      uneCompetence.type
                                    );
                                  }}
                                  className="btn-edit-cpt"
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              ) : null}

                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  className="btn-delete-cpt"
                                  onClick={(event) => {
                                    deletePrj(event, uneCompetence.id);
                                  }}
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              ) : null}

                              <Projet
                                uneCpt={uneCompetence}
                                imgCacheBypass={imgCacheBypass}
                              />
                            </div>
                          );

                        case "separateur":
                          return (
                            <div
                              key={uneCompetence.id}
                              className="cpt-flex-element-long"
                            >
                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  onClick={(event) => {
                                    handleEdit(
                                      event,
                                      uneCompetence.id,
                                      uneCompetence.type
                                    );
                                  }}
                                  className="btn-edit-cpt-long"
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              ) : null}

                              {sessionStorage.getItem("isCnxValid") ===
                                "true" &&
                              sessionStorage.getItem("cnxId") !== "" &&
                              sessionStorage.getItem("cnToken") !== "" ? (
                                <button
                                  className="btn-delete-cpt-long"
                                  onClick={(event) => {
                                    deleteSpt(event, uneCompetence.id);
                                  }}
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              ) : null}

                              <Separateur
                                uneCpt={uneCompetence}
                                index={index}
                              />
                            </div>
                          );
                        default:
                          return null;
                      }
                    })
                  : null}
              </div>
            ) : (
              <div className="lien-flex">
                {lesCompetences.data.map((unLien) => {
                  return (
                    <div className="lien-flex-element" key={unLien.id}>
                      <BtnLien unLien={unLien} />
                    </div>
                  );
                })}
              </div>
            )}

            {sessionStorage.getItem("isCnxValid") === "true" &&
            sessionStorage.getItem("cnxId") !== "" &&
            sessionStorage.getItem("cnToken") !== "" ? (
              msgErrDelete !== "" ? (
                <p className="msg-err-delete">{msgErrDelete}</p>
              ) : null
            ) : null}

            {sessionStorage.getItem("isCnxValid") === "true" &&
            sessionStorage.getItem("cnxId") !== "" &&
            sessionStorage.getItem("cnToken") !== "" ? (
              (typeof lesCompetences.catPrjData !== "undefined" &&
                lesCompetences.catPrjData.type !== "projet") ||
              typeof lesCompetences.catPrjData === "undefined" ? (
                <FormAddCpt
                  isAddMenuActive={isAddMenuActive}
                  idSousCat={idSousCat}
                  libFormActive={libFormActive}
                  setIsAddMenuActive={setIsAddMenuActive}
                  setLibFormActive={setLibFormActive}
                  setMsgErrDelete={setMsgErrDelete}
                  addCat={addCat}
                  addPrj={addPrj}
                  addSpt={addSpt}
                />
              ) : null
            ) : null}
          </div>
        )}

        <div className="page-body-grid-footer">
          <Footer />
        </div>
      </div>
      <div className="footer-space"></div>
    </div>
  );
};

export default Competences;
