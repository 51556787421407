import React from "react";

const Separateur = (props) => {
  return (
    <div className="grid-separateur">
      <div className={props.index > 0 ? "grid-separateur-ligne" : null}></div>
      <div className="grid-separateur-titre">
        <p
          className={
            props.index > 0 ? "separateur-titre" : "separateur-titre-ligne-null"
          }
        >
          {props.uneCpt.titre + " :"}
        </p>
      </div>
    </div>
  );
};

export default Separateur;
