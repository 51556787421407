import React from "react";

const MoyensContact = ({ email, num }) => {
  return (
    <div className="moyens-contact-grid">
      <div className="moyens-contact-grid-element moyens-contact-grid-element-long">
        <p>
          - <span className="text-underline">Adresse e-mail :</span>
        </p>
        <a draggable="false" className="mail-long" href={"mailto:" + email}>
          {email}
        </a>
      </div>

      <div className="moyens-contact-grid-element moyens-contact-grid-element-long moyens-contact-grid-element-centrer moyens-contact-grid-element-centrer-mail">
        <a draggable="false" href={"mailto:" + email}>
          {email}
        </a>
      </div>

      <div className="moyens-contact-grid-element moyens-contact-grid-element-long">
        <p>
          - <span className="text-underline">Numéro de téléphone :</span>
        </p>
        <a draggable="false" className="num-long" href={"tel:" + num}>
          {num}
        </a>
      </div>

      <div className="moyens-contact-grid-element moyens-contact-grid-element-long moyens-contact-grid-element-centrer">
        <a draggable="false" href={"tel:" + num}>
          {num}
        </a>
      </div>
    </div>
  );
};

export default MoyensContact;
