import React, { useState } from "react";

const FormLogin = ({ handleSubmit, errLogin }) => {
  const [isPswVisible, setIsPswVisible] = useState(false);

  const handlePswVisible = (event) => {
    event.preventDefault();

    !isPswVisible ? setIsPswVisible(true) : setIsPswVisible(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-grid-login">
        <div className="form-grid-login-element">
          <img
            className="login-user-icon"
            alt="Icon utilisateurs"
            src="./img/user-icon.png"
            draggable="false"
            height="150"
            width="150"
          />
        </div>

        <div className="form-grid-login-element">
          <h1 className="login-titre">
            Connexion au compte administrateur du portfolio
          </h1>
        </div>

        <div className="form-grid-login-element">
          <h2>Identifiant :</h2>
        </div>

        <div className="form-grid-login-element">
          <input id="identifiant" type="text" />
        </div>

        <div className="form-grid-login-element">
          <h2 className="mdp-titre-margin">Mot de passe :</h2>
        </div>

        <div className="form-grid-login-element form-grid-login-element-input-mdp">
          <input
            id="mdp"
            className="input-mdp-login"
            type={isPswVisible ? "text" : "password"}
          />
          <i
            onClick={handlePswVisible}
            className={
              "fa-regular " +
              (!isPswVisible
                ? "fa-eye eye-psw-login-visible"
                : "fa-eye-slash eye-psw-login-invisible") +
              " eye-psw-login"
            }
          ></i>
        </div>

        <div className="form-grid-login-element">
          <button type="submit">Connexion</button>
        </div>

        <div className="form-grid-login-element">
          {errLogin ? <p className="message-err-login">{errLogin}</p> : null}
        </div>
      </div>
    </form>
  );
};

export default FormLogin;
