import React from "react";
import { useNavigate } from "react-router-dom";

const BtnDeconnecte = (props) => {
  const navigate = useNavigate();

  const t = () => {
    return setInterval(() => {});
  };

  const handleDeconnexion = (event) => {
    event.preventDefault();

    let interval = t();

    for (let i = 0; i < interval + 1; i++) {
      clearInterval(i);
    }

    sessionStorage.setItem("isCnxValid", false);
    sessionStorage.setItem("cnxId", "");
    sessionStorage.setItem("cnxToken", "");
    navigate(props.page);
  };

  return (
    <i onClick={handleDeconnexion}>
      Se déconnecter ({sessionStorage.getItem("cnxId")})
    </i>
  );
};

export default BtnDeconnecte;
