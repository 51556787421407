import React, { useState } from "react";
import axios from "axios";

const FormCV = ({ setModifCv, update, setImgCacheBypass }) => {
  const [selectedImgCvFileName, setSelectedImgCvFileName] = useState("None");
  const [selectedPdfCvFileName, setSelectedPdfCvFileName] = useState("None");
  const [msgErrEditCv, setMsgErrEditCv] = useState("");

  const handleRetourCvBtn = (event) => {
    event.preventDefault();

    setModifCv(false);
  };

  const handleInputImgCvChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgCvFileName(file.name);
    }
  };

  const handleInputPdfCvChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedPdfCvFileName(file.name);
    }
  };

  const handleSubmitCv = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("fileuploadImgCv", form.imgcv.files[0]);
    formData.append("fileuploadPdfCv", form.pdfcv.files[0]);

    axios
      .put(process.env.REACT_APP_API_URL + "modifCv", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Erreur") {
          setMsgErrEditCv(res.data.message);
        } else {
          axios
            .get(process.env.REACT_APP_API_URL + "getImgPdfCV", {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            })
            .then((res) => {
              setImgCacheBypass(Math.random().toString(36));
              update(res.data.data.imgPreview, res.data.data.pdf);
            });
        }
      });
  };

  return (
    <form onSubmit={handleSubmitCv}>
      <div className="page-cv-grid-form">
        <div className="page-cv-grid-form-element">
          <p className="img-preview-titre">Image de prévisualisation :</p>
        </div>

        <div className="page-cv-grid-form-element">
          <input id="imgcv" type="file" onChange={handleInputImgCvChange} />
          <label className="btn-upload-img-cv" htmlFor="imgcv">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-img-cv-titre">
            <span className="titre-img-cv-deco">Fichier sélectionné :</span>
            {" " + selectedImgCvFileName}
          </p>
        </div>

        <div className="page-cv-grid-form-element">
          <p className="cv-pdf-titre">CV (PDF):</p>
        </div>

        <div className="page-cv-grid-form-element">
          <input id="pdfcv" type="file" onChange={handleInputPdfCvChange} />
          <label className="btn-upload-pdf-cv" htmlFor="pdfcv">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-pdf-cv-titre">
            <span className="titre-pdf-cv-deco">Fichier sélectionné :</span>
            {" " + selectedPdfCvFileName}
          </p>
        </div>

        <div className="page-cv-grid-form-element">
          <button className="btn-cv-form-valider" type="submit">
            Valider
          </button>
        </div>
        <div className="page-cv-grid-form-element">
          <button onClick={handleRetourCvBtn} className="btn-cv-retour">
            Retour
          </button>
        </div>

        <div className="page-cv-grid-form-element">
          {msgErrEditCv !== "" ? (
            <p className="msgErrEditCv">{msgErrEditCv}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormCV;
