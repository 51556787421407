import React, { useState } from "react";
import axios from "axios";

const FormCategorie = ({
  idSousCat,
  action,
  add,
  update,
  data,
  setImgCacheBypass,
}) => {
  const [selectedImgCatFileName, setSelectedImgCatFileName] = useState("None");
  const [msgErrAddUpdateCat, setMsgErrAddUpdateCat] = useState("");

  const handleInputImgCatChange = (event) => {
    event.preventDefault();

    const file = event.currentTarget.files[0];

    if (typeof file !== "undefined") {
      setSelectedImgCatFileName(file.name);
    }
  };

  const handleSubmitCat = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    switch (action) {
      case "add":
        let formData = new FormData();

        formData.append("titre", form.titrecat.value);
        formData.append("fileupload", form.imgcat.files[0]);
        formData.append("description", form.descriptioncat.value);

        if (idSousCat !== null) {
          formData.append("idSousCat", idSousCat);
        } else {
          formData.append("idSousCat", "");
        }

        axios
          .post(process.env.REACT_APP_API_URL + "ajoutCategorie", formData, {
            headers: {
              identifiant: process.env.REACT_APP_API_ID,
              authorization: "Bearer " + process.env.REACT_APP_API_KEY,
            },
          })
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateCat(res.data.message);
            } else {
              axios
                .get(process.env.REACT_APP_API_URL + "getDernierCptId", {
                  headers: {
                    identifiant: process.env.REACT_APP_API_ID,
                    authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                  },
                })
                .then((res) => {
                  const cptIdAdd = res.data.dernierCptId;

                  axios
                    .get(
                      process.env.REACT_APP_API_URL +
                        "getInfoAddCptId/categorie/" +
                        cptIdAdd,
                      {
                        headers: {
                          identifiant: process.env.REACT_APP_API_ID,
                          authorization:
                            "Bearer " + process.env.REACT_APP_API_KEY,
                        },
                      }
                    )
                    .then((res) => {
                      add({
                        id: cptIdAdd,
                        titre: form.titrecat.value,
                        type: "categorie",
                        data: {
                          urlId: res.data.data.urlId,
                        },
                      });
                    });
                });
            }
          });

        break;

      case "update":
        let formDataUpdate = new FormData();

        if (form.titrecat.value !== "") {
          formDataUpdate.append("titre", form.titrecat.value);
        } else {
          formDataUpdate.append("titre", null);
        }

        if (form.descriptioncat.value !== "") {
          formDataUpdate.append("description", form.descriptioncat.value);
        } else {
          formDataUpdate.append("description", null);
        }

        formDataUpdate.append("fileupload", form.imgcat.files[0]);

        axios
          .put(
            process.env.REACT_APP_API_URL + "modifCategorie/" + data.id,
            formDataUpdate,
            {
              headers: {
                identifiant: process.env.REACT_APP_API_ID,
                authorization: "Bearer " + process.env.REACT_APP_API_KEY,
              },
            }
          )
          .then((res) => {
            if (res.data.titre === "Erreur") {
              setMsgErrAddUpdateCat(res.data.message);
            } else {
              axios
                .get(
                  process.env.REACT_APP_API_URL +
                    "getInfoUpdateCptId/" +
                    data.type +
                    "/" +
                    data.id,
                  {
                    headers: {
                      identifiant: process.env.REACT_APP_API_ID,
                      authorization: "Bearer " + process.env.REACT_APP_API_KEY,
                    },
                  }
                )
                .then((res) => {
                  setImgCacheBypass(Math.random().toString(36));
                  update({
                    id: data.id,
                    titre: res.data.data.titre,
                    type: data.type,
                    data: {
                      urlId: res.data.data.urlId,
                    },
                  });
                });
            }
          });

        break;
      default:
    }
  };

  return (
    <form onSubmit={handleSubmitCat}>
      <div className="form-cat-grid">
        <div className="form-cat-grid-element">
          <p className="form-cat-titre-titre">Titre :</p>
        </div>

        <div className="form-cat-grid-element">
          <input
            id="titrecat"
            placeholder={action === "update" ? data.titre : ""}
            type="text"
          />
        </div>

        <div className="form-cat-grid-element">
          <p className="form-cat-titre-img">Image :</p>
        </div>

        <div className="form-cat-grid-element">
          <input id="imgcat" type="file" onChange={handleInputImgCatChange} />
          <label className="btn-upload-img-cat" htmlFor="imgcat">
            Veuillez choisir un fichier...
          </label>
          <p className="btn-upload-img-cat-titre">
            <span className="titre-img-cat-deco">Fichier sélectionné :</span>
            {" " + selectedImgCatFileName}
          </p>
        </div>

        <div className="form-cat-grid-element">
          <p className="form-cat-description-titre">Description :</p>
        </div>

        <div className="form-cat-grid-element">
          <textarea
            id="descriptioncat"
            placeholder={action === "update" ? data.description : ""}
          />
        </div>

        <div className="form-cat-grid-element">
          <button className="btn-cat-form-valider" type="submit">
            Valider
          </button>
        </div>

        <div className="form-prj-grid-element">
          {msgErrAddUpdateCat !== "" ? (
            <p className="msgErrAddUpdateCat">{msgErrAddUpdateCat}</p>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default FormCategorie;
