import React, { useState, useEffect } from "react";
import axios from "axios";

const FormOTPEdit = () => {
  const [isOTPActive, setIsOTPActive] = useState(false);
  const [isOTPFormActive, setIsOTPFormActive] = useState(false);
  const [OTPKey, setOTPKey] = useState("");
  const [OTPQRCode, setOTPQRCode] = useState("");
  const [msgErrResOTPEdit, setMsgErrResOTPEdit] = useState("");
  const [isMsgErrOTPEdit, setIsMsgErrOTPEdit] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "isOTPActive", {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        setIsOTPActive(res.data.isOTPActive);
      });
  }, []);

  const handleOTPForm = (event) => {
    event.preventDefault();

    if (!isOTPActive) {
      axios
        .get(process.env.REACT_APP_API_URL + "getOTPKey", {
          headers: {
            identifiant: process.env.REACT_APP_API_ID,
            authorization: "Bearer " + process.env.REACT_APP_API_KEY,
          },
        })
        .then((res) => {
          setOTPKey(res.data.data.otp_key);
          setOTPQRCode(res.data.data.otp_qrcode);
          setIsOTPFormActive(true);
        });
    } else {
      axios
        .delete(process.env.REACT_APP_API_URL + "disableOTP", {
          headers: {
            identifiant: process.env.REACT_APP_API_ID,
            authorization: "Bearer " + process.env.REACT_APP_API_KEY,
          },
        })
        .then((res) => {
          if (msgErrResOTPEdit !== "") {
            setMsgErrResOTPEdit("");
          }

          setIsOTPActive(false);
        });
    }
  };

  const handleOTPEditFormSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    let formData = new FormData();

    formData.append("otp_key", OTPKey);
    formData.append("otp_code", form.OTPEdit.value);

    axios
      .post(process.env.REACT_APP_API_URL + "verifOTPKey", formData, {
        headers: {
          identifiant: process.env.REACT_APP_API_ID,
          authorization: "Bearer " + process.env.REACT_APP_API_KEY,
        },
      })
      .then((res) => {
        if (res.data.titre === "Succès") {
          setOTPKey("");
          setOTPQRCode("");
          setIsOTPActive(true);
          setIsOTPFormActive(false);
          setIsMsgErrOTPEdit(false);
          setMsgErrResOTPEdit(res.data.message);
        } else {
          setIsMsgErrOTPEdit(true);
          setMsgErrResOTPEdit(res.data.message);
        }
      });
  };

  return !isOTPFormActive ? (
    <div className="grid-otp-edit">
      <div className="grid-otp-edit-element grid-otp-edit-element-titre">
        <h2>Activer la double authentification :</h2>
      </div>

      <div className="grid-otp-edit-element grid-otp-edit-element-left">
        <p className="otp-edit-form-titre otp-edit-form-titre-left">
          Google Authenticator :
        </p>
      </div>

      <div className="grid-otp-edit-element grid-otp-edit-element-right">
        <button
          onClick={handleOTPForm}
          className={
            "btn-actv-dectv-otp-edit " +
            (isOTPActive ? "btn-dectv-otp-edit" : "btn-actv-otp-edit")
          }
        >
          {isOTPActive ? "Désactiver" : "Activer"}
        </button>
      </div>

      {msgErrResOTPEdit !== "" ? (
        <div className="grid-otp-edit-element grid-otp-edit-element-long">
          <p
            className={
              "message-err-res-otp-edit-contact " +
              (isMsgErrOTPEdit
                ? "message-err-otp-edit-contact"
                : "message-res-otp-edit-contact")
            }
          >
            {msgErrResOTPEdit}
          </p>
        </div>
      ) : null}
    </div>
  ) : (
    <form onSubmit={handleOTPEditFormSubmit}>
      <div className="grid-otp-edit">
        <div className="grid-otp-edit-element grid-otp-edit-element-titre">
          <h2>Activer la double authentification :</h2>
        </div>

        <div className="grid-otp-edit-element grid-otp-edit-element-long">
          <img
            src={OTPQRCode}
            alt="QR Code pour activer la double authentification"
            draggable="false"
            height="196"
            width="196"
          />
        </div>

        <div className="grid-otp-edit-element grid-otp-edit-element-long">
          <input id="OTPEdit" placeholder="XXXXXX" maxLength="6" type="text" />
        </div>

        <div className="grid-otp-edit-element grid-otp-edit-element-long">
          <button className="btn-contact-otp-edit-form-valider" type="submit">
            Valider
          </button>
        </div>

        {msgErrResOTPEdit !== "" ? (
          <div className="grid-otp-edit-element grid-otp-edit-element-long">
            <p
              className={
                "message-err-res-otp-edit-contact " +
                (isMsgErrOTPEdit
                  ? "message-err-otp-edit-contact"
                  : "message-res-otp-edit-contact")
              }
            >
              {msgErrResOTPEdit}
            </p>
          </div>
        ) : null}
      </div>
    </form>
  );
};

export default FormOTPEdit;
